import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr'; // add toster service

@Injectable()
export class ChangeDateFormatService {
  dateDay: string | number;
  dateMonth: string | number;
  dateYear: string | number;

  FullYear: string | number;
  currentDate = new Date();

  constructor(
    private toastrService: ToastrService,
  ) {
  }

  /**
   * @description : This Function is used date format separator.
   * @params : dateFormat
   * @return date object
   */
  getDateFormatDelimeters(dateFormat) {
    return dateFormat.match(/[^(dmy)]{1,}/g);
  }

  isValidDate(dt) {
    return dt instanceof Date;
  }

  getTimeInMilliseconds(someDate) {
    return this.getDate(someDate.date.year, someDate.date.month, someDate.date.day).getTime();
  }

  getDate(year, month, day) {
    return new Date(year, month - 1, day, 0, 0, 0, 0);
  }

  /**
   * Following function return date in string format dd-mm yyyy
   * @param dateValue :dateobj accepted as date obj. we are returning the object into string
   */
  convertDateObjectToString(dateValue: any) {
    const dateValueUpdated = dateValue.date;
    if (dateValueUpdated != null && dateValueUpdated !== '' && dateValueUpdated !== undefined) {
      let dayVal;
      let monVal;
      if (dateValueUpdated.day >= 10) {
        dayVal = dateValueUpdated.day;
      } else {
        dayVal = '0' + dateValueUpdated.day;
      }
      if (dateValueUpdated.month >= 10) {
        monVal = dateValueUpdated.month;
      } else {
        monVal = '0' + dateValueUpdated.month;
      }
      const finalDate = monVal + '/' + dayVal + '/' + dateValueUpdated.year;
      return finalDate;
    } else {
      return null;
    }
  }

  /**
   * Following function return time in string format hh:mm
   * @param timeValue :timeobj accepted as time obj. we are returning the object into string
   */
  convertTimeObjectToString(timeValue: any) {
    if (timeValue != null && timeValue !== '' && timeValue !== undefined) {
      let hourVal;
      let minVal;
      if (timeValue.hour >= 10) {
        hourVal = timeValue.hour;
      } else {
        hourVal = '0' + timeValue.hour;
      }
      if (timeValue.minute >= 10) {
        minVal = timeValue.minute;
      } else {
        minVal = '0' + timeValue.minute;
      }
      const finalTime = hourVal + ':' + minVal;
      return finalTime;
    } else {
      return null;
    }
  }

  /**
   * Following function return date string in object format
   * @param strDate : date will accepted as a string format.
   */
  convertStringDateToObject(strDate: string) {
    if (strDate != null && strDate !== '' && strDate !== undefined) {
      const objDate = strDate.split('/');
      let mnth;
      mnth = parseInt(objDate[0], 10);
      if (mnth.toString() === 'NaN') {
        const monthLabels = { Jan: 1, Feb: 2, Mar: 3, Apr: 4, May: 5, Jun: 6, Jul: 7, Aug: 8, Sep: 9, Oct: 10, Nov: 11, Dec: 12 };
        mnth = monthLabels[objDate[0]];
      }
      return {
        date: {
          year: parseInt(objDate[2], 10),
          month: parseInt(mnth),
          day: parseInt(objDate[1], 10)
        }
      };
    } else {
      return null;
    }
  }

  /**
   * Following function return time string in object format
   * @param strTime : time will accepted as a string format.
   */
  convertStringTimeToObject(strTime: string) {
    if (strTime != null && strTime !== '' && strTime !== undefined) {
      const objDate = strTime.split(':');
      return {
          hour: parseInt(objDate[0], 10),
          minute: parseInt(objDate[1], 10)
      };
    } else {
      return null;
    }
  }

  formatDateObject(date) {
    return {
      date: {
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        day: date.getDate()
      }
    };
  }

  formatTime(strTime) {
    if (strTime != null && strTime !== '' && strTime !== undefined) {
      const firstTimeObjTime = strTime.split(':');
      const finalValue = firstTimeObjTime[0] + ':' + firstTimeObjTime[1];
      return finalValue;
    }
  }

  formatTimeObject(strTime) {
    if (strTime != null && strTime !== '' && strTime !== undefined) {
      const countStr = strTime.length;
      const matchStr = strTime.match(':');
      if (countStr > 2 && matchStr === null) {
        const splitValue = strTime.split('');
        const firstSplitValue = splitValue[0] + splitValue[1];
        const secondSplitValue = splitValue[2] + splitValue[3];
        strTime = firstSplitValue + ':' + secondSplitValue;
        // if (firstSplitValue > 24) {
        //   firstSplitValue = '0' + splitValue[0];
        //   secondSplitValue = splitValue[1] + splitValue[2];
        //   strTime = firstSplitValue + ':' + secondSplitValue;
        // }
      }
      const firstTimeObjTime = strTime.split(':');
      let firstTimeHourValue;
      let firstTimeMinuteValue;
      let finalValue;
      if (parseInt(firstTimeObjTime[0], 10) >= 10) {
        firstTimeHourValue = parseInt(firstTimeObjTime[0], 10);
      } else {
        firstTimeHourValue = '0' + parseInt(firstTimeObjTime[0], 10);
      }
      if (parseInt(firstTimeObjTime[1], 10)) {
        if (parseInt(firstTimeObjTime[1], 10) >= 10) {
          firstTimeMinuteValue = parseInt(firstTimeObjTime[1], 10);
        } else {
          firstTimeMinuteValue = '0' + parseInt(firstTimeObjTime[1], 10);
        }
      } else {
        firstTimeMinuteValue = '00';
      }
      if (firstTimeHourValue < 24 && firstTimeMinuteValue >= 60) {
        firstTimeHourValue = firstTimeHourValue + 1;
        firstTimeMinuteValue = firstTimeMinuteValue - 60 ;
      }
      // if (firstTimeHourValue === 24 && firstTimeMinuteValue === '00') {
      //   firstTimeHourValue = '00';
      //   firstTimeMinuteValue = '00' ;
      // }
      finalValue = firstTimeHourValue + ':' + firstTimeMinuteValue;
      if (firstTimeHourValue === 24) {
        if (firstTimeMinuteValue >= 0) {
          this.toastrService.error('Please enter valid time');
          return null;
        }
      }
      if (firstTimeHourValue > 24) {
        if (firstTimeMinuteValue >= 0) {
          this.toastrService.error('Please enter valid time');
          return null;
        }
      }
     
      // if (firstTimeHourValue === '00' && firstTimeMinuteValue === '00') {
      //     this.toastrService.error('Repetition time duration should be greater than zero');
      //     return null;
      // }
      return finalValue;
    } else {
      return null;
    }
  }

  formatEmployeeTimeObject(strTime) {
    if (strTime != null && strTime !== '' && strTime !== undefined) {
      const countStr = strTime.length;
      const matchStr = strTime.match(':');
      if (countStr > 2 && matchStr === null) {
        const splitValue = strTime.split('');
        const firstSplitValue = splitValue[0] + splitValue[1];
        const secondSplitValue = splitValue[2] + splitValue[3];
        strTime = firstSplitValue + ':' + secondSplitValue;
        // if (firstSplitValue > 24) {
        //   firstSplitValue = '0' + splitValue[0];
        //   secondSplitValue = splitValue[1] + splitValue[2];
        //   strTime = firstSplitValue + ':' + secondSplitValue;
        // }
      }
      const firstTimeObjTime = strTime.split(':');
      let firstTimeHourValue;
      let firstTimeMinuteValue;
      let finalValue;
      if (parseInt(firstTimeObjTime[0], 10) >= 10) {
        firstTimeHourValue = parseInt(firstTimeObjTime[0], 10);
      } else {
        firstTimeHourValue = '0' + parseInt(firstTimeObjTime[0], 10);
      }
      if (parseInt(firstTimeObjTime[1], 10)) {
        if (parseInt(firstTimeObjTime[1], 10) >= 10) {
          firstTimeMinuteValue = parseInt(firstTimeObjTime[1], 10);
        } else {
          firstTimeMinuteValue = '0' + parseInt(firstTimeObjTime[1], 10);
        }
      } else {
        firstTimeMinuteValue = '00';
      }
      if (firstTimeHourValue < 24 && firstTimeMinuteValue >= 60) {
        firstTimeHourValue = firstTimeHourValue + 1;
        firstTimeMinuteValue = firstTimeMinuteValue - 60 ;
      }
      // if (firstTimeHourValue === 24 && firstTimeMinuteValue === '00') {
      //   firstTimeHourValue = '00';
      //   firstTimeMinuteValue = '00' ;
      // }
      finalValue = firstTimeHourValue + ':' + firstTimeMinuteValue;
      if (firstTimeHourValue === 24) {
        if (firstTimeMinuteValue >= 0) {
          this.toastrService.error('Please enter valid time');
          return null;
        }
      }
      if (firstTimeHourValue > 24) {
        if (firstTimeMinuteValue >= 0) {
          this.toastrService.error('Please enter valid time');
          return null;
        }
      }
      return finalValue;
    } else {
      return null;
    }
  }

  /**
   * Following function return time in string format hh:mm
   * @param timeValue :timeobj accepted as time obj. we are returning the object into string
   */
  compareTime(firstTimeValue: any, secondTimeValue: any) {
    if ((firstTimeValue != null && firstTimeValue !== '' && firstTimeValue !== undefined) &&
    (secondTimeValue != null && secondTimeValue !== '' && secondTimeValue !== undefined)) {
      const firstTimeObjTime = firstTimeValue.split(':');
      const secondTimeObjTime = secondTimeValue.split(':');
      let firstTimeHourValue;
      let firstTimeMinuteValue;
      let finalFirstTimeValue;
      let secondTimeHourValue;
      let secondTimeMinuteValue;
      let finalSecondTimeValue;

      if (parseInt(firstTimeObjTime[0], 10) >= 10) {
        firstTimeHourValue = parseInt(firstTimeObjTime[0], 10);
      } else {
        firstTimeHourValue = '0' + parseInt(firstTimeObjTime[0], 10);
      }
      if (parseInt(firstTimeObjTime[1], 10) >= 10) {
        firstTimeMinuteValue = parseInt(firstTimeObjTime[1], 10);
      } else {
        firstTimeMinuteValue = '0' + parseInt(firstTimeObjTime[1], 10);
      }
      finalFirstTimeValue = firstTimeHourValue + ':' + firstTimeMinuteValue;

      if (parseInt(secondTimeObjTime[0], 10) >= 10) {
        secondTimeHourValue = parseInt(secondTimeObjTime[0], 10);
      } else {
        secondTimeHourValue = '0' + parseInt(secondTimeObjTime[0], 10);
      }
      if (parseInt(secondTimeObjTime[1], 10) >= 10) {
        secondTimeMinuteValue = parseInt(secondTimeObjTime[1], 10);
      } else {
        secondTimeMinuteValue = '0' + parseInt(secondTimeObjTime[1], 10);
      }
      finalSecondTimeValue = secondTimeHourValue + ':' + secondTimeMinuteValue;
      if(finalSecondTimeValue === '00:00' ){
        finalSecondTimeValue = '24:00';
      } else {
        finalSecondTimeValue = finalSecondTimeValue;
      }
      if (finalFirstTimeValue >= finalSecondTimeValue) {
        return true;
      } else {
        return false;
      }
    } else {
      return null;
    }
  }


  // Convert a time in hh:mm format to minutes
  timeToMins(time) {
    const b = time.split(':');
    return b[0] * 60 + +b[1];
  }

  // Convert minutes to a time in format hh:mm
  // Returned value is in range 00  to 24 hrs
  timeFromMins(mins) {
    function z(n) { return (n < 10 ? '0' : '') + n; }
    const h = (mins / 60 | 0) % 24;
    const m = mins % 60;
    return z(h) + ':' + z(m) + ':00';
  }

  // Add two times in hh:mm format
  addTimes(t0, t1) {
    return this.timeFromMins(this.timeToMins(t0) + this.timeToMins(t1));
  }

  /**
   * Following function return time in string format hh:mm
   * @param firstTimeValue :timeobj accepted as time obj. we are returning the object into string
   */
  getTimeDiff(firstTimeValue: any, secondTimeValue: any) {
    if ((firstTimeValue != null && firstTimeValue !== '' && firstTimeValue !== undefined) &&
     (secondTimeValue != null && secondTimeValue !== '' && secondTimeValue !== undefined)) {
      const firstTimeObjTime = firstTimeValue.split(':');
      const secondTimeObjTime = secondTimeValue.split(':');
      let firstTimeHourValue;
      let firstTimeMinuteValue;
      let finalFirstTimeValue;
      let secondTimeHourValue;
      let secondTimeMinuteValue;
      let finalSecondTimeValue;
      let finalTimeValue;

      if (parseInt(firstTimeObjTime[0], 10) >= 10) {
        firstTimeHourValue = parseInt(firstTimeObjTime[0], 10);
      } else {
        firstTimeHourValue = '0' + parseInt(firstTimeObjTime[0], 10);
      }
      if (parseInt(firstTimeObjTime[1], 10) >= 10) {
        firstTimeMinuteValue = parseInt(firstTimeObjTime[1], 10);
      } else {
        firstTimeMinuteValue = '0' + parseInt(firstTimeObjTime[1], 10);
      }
      finalFirstTimeValue = new Date();
      finalFirstTimeValue.setHours(firstTimeHourValue);
      finalFirstTimeValue.setMinutes(firstTimeMinuteValue);
      if (parseInt(secondTimeObjTime[0], 10) >= 10) {
        secondTimeHourValue = parseInt(secondTimeObjTime[0], 10);
      } else {
        secondTimeHourValue = '0' + parseInt(secondTimeObjTime[0], 10);
      }
      if (parseInt(secondTimeObjTime[1], 10) >= 10) {
        secondTimeMinuteValue = parseInt(secondTimeObjTime[1], 10);
      } else {
        secondTimeMinuteValue = '0' + parseInt(secondTimeObjTime[1], 10);
      }
      finalSecondTimeValue = new Date();
      finalSecondTimeValue.setHours(secondTimeHourValue);
      finalSecondTimeValue.setMinutes(secondTimeMinuteValue);
      let diff  = finalSecondTimeValue.getTime() - finalFirstTimeValue.getTime();
      firstTimeValue = Math.floor(diff / (1000 * 60 * 60));
      diff -= firstTimeValue * (1000 * 60 * 60);
      secondTimeValue = Math.floor(diff / (1000 * 60));
      diff -= secondTimeValue * (1000 * 60);
      finalTimeValue = firstTimeValue + ':' + secondTimeValue;
      return finalTimeValue;
    } else {
      return null;
    }
  }

  /**
   * Function to convert time hours string to minuts
   * @param timeString timeString
   */
  convertHoursToMin(timeString) {
    if ((timeString != null && timeString !== '' && timeString !== undefined)) {
      const splitTimeString = timeString.split(':');
      const timeInMins = parseInt(splitTimeString[0], 10) * 60 + parseInt(splitTimeString[1], 10);
      return timeInMins;
    } else {
      return null;
    }
  }

  checkTimeExistBetweenTwoTimes(repetitionStartTime, repetitionEndTime, breakStartTimeString, breakEndTimeString) {
    if ((repetitionStartTime != null && repetitionStartTime !== '' && repetitionStartTime !== undefined)
    && (repetitionEndTime != null && repetitionEndTime !== '' && repetitionEndTime !== undefined)
    && (breakStartTimeString != null && breakStartTimeString !== '' && breakStartTimeString !== undefined)
    && (breakEndTimeString != null && breakEndTimeString !== '' && breakEndTimeString !== undefined)
    ) {
      let isTimeExist;
      const dt = new Date();
      const bs =  breakStartTimeString.split(':');
      const bs1 = new Date(dt.getFullYear(), dt.getMonth(), dt.getDate(), parseInt(bs[0], 10), parseInt(bs[1], 10));

      const be =  breakEndTimeString.split(':');
      const be1 = new Date(dt.getFullYear(), dt.getMonth(), dt.getDate(), parseInt(be[0], 10), parseInt(be[1], 10));

      const s =  repetitionStartTime.split(':');
      const dt1 = new Date(dt.getFullYear(), dt.getMonth(), dt.getDate(), parseInt(s[0], 10), parseInt(s[1], 10));

      const e =  repetitionEndTime.split(':');
      const dt2 = new Date(dt.getFullYear(), dt.getMonth(), dt.getDate(),parseInt(e[0], 10), parseInt(e[1], 10));
      if (bs1 >= dt1 && bs1 < dt2) {
        isTimeExist = true;
      } else if (be1 > dt1 && be1 <= dt2) {
        isTimeExist = true;
      } else if (bs1 < dt1 && be1 > dt2) {
        isTimeExist = true;
      } else {
        isTimeExist = false;
      }
      return isTimeExist;
     } else {
      return null;
     }
  }

  /**
   * Following function return date string for full calender
   * @param strDate : date will accepted as a string format.
   */
  formatDateForFullCalender(serviceDate: string, startTime: string, type: string) {
    if (serviceDate != null && serviceDate !== '' && serviceDate !== undefined
    && (startTime != null && startTime !== '' && startTime !== undefined)) {
      const objDate = serviceDate.split('/');
      const formatedDate = objDate[2] + '-'  + objDate[0] + '-' +  objDate[1];
      const formatedTime = this.timeConvertor(startTime);
      let dateTime;
      if(type === 'employee_timing_slots'){
         dateTime = formatedDate;
      } else{
         dateTime = formatedDate+formatedTime;
      }
     
      return dateTime;
    } else {
      return null;
    }
  }

  timeConvertor(time) {
    if (time) {
      const PM = time.match('PM') ? true : false;
      time = time.split(':');
      let min; let hour;
      if (PM) {
          hour = 12 + parseInt(time[0], 10);
          min = time[1].replace('PM', '');
      } else {
        hour = time[0];
        min = time[1].replace('AM', '');
      }
      return 'T' + hour + ':' + min;
    } else {
      return null;
    }
  }

  timeConvertorTo12HoursFormat(time) {
    if (time) {
      const PM = time.match('PM') ? true : false;
      time = time.split(':');
      let min;
      let hour;
      let hour1;
      if (PM) {
        if(time[0] !== '12'){
          hour1 = 12 + parseInt(time[0], 10);
        } else {
          hour1 = 0 + parseInt(time[0], 10);
        }
          hour = hour1;
          min = time[1].replace('PM', '');
      } else {
        if(time[0] !== '12'){
          hour1 =  parseInt(time[0], 10);
        } else {
          hour1 = '00';
        }
        hour = hour1;
        min = time[1].replace('AM', '');
      }

      return hour + ':' + min;
    } else {
      return null;
    }
  }

  /**
   * Function to compare two dates
   * @param startDate startDate
   * @param endDate endDate
   */
  compareTwoDates(startDate, endDate) {
    // covert end date to unix time stamp
    if ((startDate !== '' && startDate !== undefined) && (endDate !== '' && endDate !== undefined)) {
      const theStartDate = Math.round(this.getTimeInMilliseconds(startDate) / 1000.0);

      // covert end date to unix time stamp
      const theEndDate = Math.round(this.getTimeInMilliseconds(endDate) / 1000.0);

      if (new Date(theEndDate) < new Date(theStartDate)) {
        return false;
      } else {
        return true;
      }
    }
  }

  /**
   * Check Time Exist Between two times
   * @param startTime startTime
   * @param endTime endTime
   * @param timeString timeString
   */
  verifyTimeExistBetweenTwoTimes(startTime, endTime, timeString, TimeType) {
    if ((startTime != null && startTime !== '' && startTime !== undefined)
    && (endTime != null && endTime !== '' && endTime !== undefined)
    && (timeString != null && timeString !== '' && timeString !== undefined)
    ) {
      let isTimeExist;
      const dt = new Date();

      const bs =  timeString.split(':');
      const bs1 = new Date(dt.getFullYear(), dt.getMonth(), dt.getDate(), parseInt(bs[0], 10), parseInt(bs[1], 10));

      const s =  startTime.split(':');
      const dt1 = new Date(dt.getFullYear(), dt.getMonth(), dt.getDate(), parseInt(s[0], 10), parseInt(s[1], 10));

      const e =  endTime.split(':');
      const dt2 = new Date(dt.getFullYear(), dt.getMonth(), dt.getDate(), parseInt(e[0], 10), parseInt(e[1], 10));
      if ((dt1 <= bs1 || dt2 < bs1 ) && TimeType === 'start_time') {
        isTimeExist = true;
      }  else {
        isTimeExist = false;
      }
      return isTimeExist;
     } else {
      return null;
     }
  }

  /**
   * Check Schedule Time Exist Between two times
   * @param startTime startTime
   * @param endTime endTime
   * @param timeString timeString
   */
  verifyScheduleTimeExistBetweenTwoTimes(startTime, endTime, timeString, TimeType) {
    if ((startTime != null && startTime !== '' && startTime !== undefined)
    && (endTime != null && endTime !== '' && endTime !== undefined)
    && (timeString != null && timeString !== '' && timeString !== undefined)
    ) {
      let isTimeExist;
      const dt = new Date();

      const bs =  timeString.split(':');
      const bs1 = new Date(dt.getFullYear(), dt.getMonth(), dt.getDate(), parseInt(bs[0], 10), parseInt(bs[1], 10));

      const s =  startTime.split(':');
      const dt1 = new Date(dt.getFullYear(), dt.getMonth(), dt.getDate(), parseInt(s[0], 10), parseInt(s[1], 10));

      const e =  endTime.split(':');
      const dt2 = new Date(dt.getFullYear(), dt.getMonth(), dt.getDate(), parseInt(e[0], 10), parseInt(e[1], 10));
      if (bs1 >= dt1 && bs1 < dt2 && TimeType === 'start_time') {
        isTimeExist = true;
      }else if (bs1 > dt1 && bs1 <= dt2 && TimeType === 'end_time') {
        isTimeExist = true;
      } else {
        isTimeExist = false;
      }
      return isTimeExist;
     } else {
      return null;
     }

  }

  /**
   * Check Date Exist Between two dates
   * @param startDate startDate
   * @param endDate endDate
   * @param dateString dateString
   */
  verifyDateExistBetweenTwoDates(startDate, endDate, currentStartDate, currentEndDate) {
    if ((startDate != null && startDate !== '' && startDate !== undefined)
    && (endDate != null && endDate !== '' && endDate !== undefined)
    && (currentStartDate != null && currentStartDate !== '' && currentStartDate !== undefined)
    && (currentEndDate != null && currentEndDate !== '' && currentEndDate !== undefined)
    ) {
      let isTimeExist;
      const theStartDate = Math.round(this.getTimeInMilliseconds(startDate) / 1000.0);
      const theEndDate = Math.round(this.getTimeInMilliseconds(endDate) / 1000.0);
      const theCurrentStartDate = Math.round(this.getTimeInMilliseconds(currentStartDate) / 1000.0);
      const theCurrentEndDate = Math.round(this.getTimeInMilliseconds(currentEndDate) / 1000.0);
      if ((new Date(theCurrentStartDate) >= new Date(theStartDate) && new Date(theCurrentStartDate) < new Date(theEndDate))
      || (new Date(theCurrentEndDate) >= new Date(theStartDate) && new Date(theCurrentStartDate) < new Date(theEndDate))) {
        return true;
      } else {
        return false;
      }
    }
  }

  /**
   * Function to convert date string to Nov,15,2019 formate
   * @param dateString
   */
  changeDateByMonthName(DateString) {
    if (DateString) {
      const monthLabels = { 1: 'Jan', 2: 'Feb', 3: 'Mar', 4: 'Apr', 5: 'May', 6: 'Jun', 7: 'Jul', 8: 'Aug', 9: 'Sep',
       10: 'Oct', 11: 'Nov', 12: 'Dec' };
      const dateArr = DateString.split('/');
      let day = dateArr[1];
      const  month = monthLabels[parseInt(dateArr[0], 10)];
      const  year = dateArr[2];
      if (day.length < 2) {
        day = '0' + day;
      }
      if (month === undefined) {
        return DateString;
      }
      if (day === 'NaN' || month === 'NaN' || year.toString() === 'NaN') {
        return '';
      } else {
        return month + ' ' + day + ', ' + year;
      }
    } else {
      return '';
    }
  }

  createSubUpdateDate(dateString) {
    if (dateString) {
      const monthLabels = { 1: 'Jan', 2: 'Feb', 3: 'Mar', 4: 'Apr', 5: 'May', 6: 'Jun', 7: 'Jul', 8: 'Aug', 9: 'Sep',
       10: 'Oct', 11: 'Nov', 12: 'Dec' };
      const dateArr = dateString.split('/');
     
      let day = dateArr[1];
      const  month = monthLabels[parseInt(dateArr[0], 10)];
      const  year = dateArr[2];
      if (day.length < 2) {
        day = '0' + day;
      }
      if (month === undefined) {
        return dateString;
      }
      if (day === 'NaN' || month === 'NaN' || year.toString() === 'NaN') {
        return '';
      } else {
        return month + ' ' + day + ', ' + year;
      }
    } else {
      return '';
    }
  }

  /**
   * Function to compare two booking slots dates
   * @param startDate startDate
   * @param endDate endDate
   */
  compareTwoBookingSlotsDates(bookingDate, currentDate) {
    // covert end date to unix time stamp
    if ((bookingDate !== '' && bookingDate !== undefined) && (currentDate !== '' && currentDate !== undefined)) {
      const theBookingDate = Math.round(this.getTimeInMilliseconds(bookingDate) / 1000.0);

      // covert end date to unix time stamp
      const theCurrentDate = Math.round(this.getTimeInMilliseconds(currentDate) / 1000.0);

      if (new Date(theBookingDate) <= new Date(theCurrentDate)) {
        return true;
      } else {
        return false;
      }
    }
  }

  /**
   * compare two times
   * @param startTime startTime
   * @param currentTime currentTime
   */
  compareTwoTimes(startTime, currentTime) {
    if ((startTime != null && startTime !== '' && startTime !== undefined)
    && (currentTime != null && currentTime !== '' && currentTime !== undefined))
    {
      let isTimeExist;
      const dt = new Date();

      const curTime =  currentTime.split(':');
      const curTime1 = new Date(dt.getFullYear(), dt.getMonth(), dt.getDate(), parseInt(curTime[0], 10), parseInt(curTime[1], 10));

      const s =  startTime.split(':');
      const strTime = new Date(dt.getFullYear(), dt.getMonth(), dt.getDate(), parseInt(s[0], 10), parseInt(s[1], 10));

       if (strTime >= curTime1) {
        isTimeExist = false;
      } else {
        isTimeExist = true;
      }
      return isTimeExist;
     } else {
      return null;
     }

  }


  /**
   * Function to compare two booking slots dates
   * @param startDate startDate
   * @param endDate endDate
   */
  compareEqualsSlotsDates(bookingDate, currentDate) {
    let isTimeExist;
    // covert end date to unix time stamp
    if ((bookingDate !== '' && bookingDate !== undefined) && (currentDate !== '' && currentDate !== undefined)) {
      const theBookingDate = Math.round(this.getTimeInMilliseconds(bookingDate) / 1000.0);

      // covert end date to unix time stamp
      const theCurrentDate = Math.round(this.getTimeInMilliseconds(currentDate) / 1000.0);
      if (theBookingDate == theCurrentDate) {
        isTimeExist = true;
      } else {
        isTimeExist = false;
      }
      return isTimeExist;
    }
  }

  /**
   * Check Date Exist Between two dates
   * @param startDate startDate
   * @param endDate endDate
   * @param dateString dateString
   */
  verifyOverlappingScheduleDate(startDate, endDate, currentStartDate, currentEndDate) {
    if ((startDate != null && startDate !== '' && startDate !== undefined)
    && (endDate != null && endDate !== '' && endDate !== undefined)
    && (currentStartDate != null && currentStartDate !== '' && currentStartDate !== undefined)
    && (currentEndDate != null && currentEndDate !== '' && currentEndDate !== undefined)
    ) {
      let isTimeExist;
      const theStartDate = Math.round(this.getTimeInMilliseconds(startDate) / 1000.0);
      const theEndDate = Math.round(this.getTimeInMilliseconds(endDate) / 1000.0);
      const theCurrentStartDate = Math.round(this.getTimeInMilliseconds(currentStartDate) / 1000.0);
      const theCurrentEndDate = Math.round(this.getTimeInMilliseconds(currentEndDate) / 1000.0);
      if ((new Date(theCurrentStartDate) >= new Date(theStartDate) && new Date(theCurrentStartDate) <= new Date(theEndDate))
      || (new Date(theCurrentEndDate) >= new Date(theStartDate) && new Date(theCurrentStartDate) <= new Date(theEndDate))) {
        return true;
      } else {
        return false;
      }
    }
  }

  formatDate(serviceDate: string) {
    
    if (serviceDate != null && serviceDate !== '' && serviceDate !== undefined) {
      const objDate = serviceDate.split('/');
      const  month = parseInt(objDate[1], 10);
      const formatedDate = month + '/'  + objDate[0] + '/' +  objDate[2];

      const dateTime = formatedDate;
      return dateTime;
    } else {
      return null;
    }
  }

  /**
   * Function to compare two booking slots dates
   * @param startDate startDate
   * @param endDate endDate
   */
  checkLessFromCurrentSlotsDates(bookingDate, currentDate) {
    let isTimeExist;
    // covert end date to unix time stamp
    if ((bookingDate !== '' && bookingDate !== undefined) && (currentDate !== '' && currentDate !== undefined)) {
      const theBookingDate = Math.round(this.getTimeInMilliseconds(bookingDate) / 1000.0);
      // covert end date to unix time stamp
      const theCurrentDate = Math.round(this.getTimeInMilliseconds(currentDate) / 1000.0);
      if (theBookingDate <= theCurrentDate) {
        isTimeExist = true;
      } else {
        isTimeExist = false;
      }
      return isTimeExist;
    }
  }

  /*
  */ 
 convertUtcDateTimeFormat(service_date){
  const localdate1 = new Date(service_date + 'Z').toLocaleString('it-IT', { timeZone: 'Canada/Pacific' });
  //const localdate1 = formatDate(localdate, ' MMM d, yyyy hh:mm a', "en");
  const date1 = localdate1.split(",")[0];
  const dd = (date1.split("/")[0].length <2) ?'0'+date1.split("/")[0]:date1.split("/")[0];
  const mm = (date1.split("/")[1].length <2) ?'0'+date1.split("/")[1]:date1.split("/")[1];
  const yy = date1.split("/")[2];
  const formatedDate = mm + '/' + dd + '/' + yy;
  const date2 = localdate1.split(",")[1];
  const hrs = date2.split(":")[0];
  const min = date2.split(":")[1];
  const formatedDate1 = hrs + ':' + min;
  return formatedDate + ' ' + formatedDate1;
 } 

}
