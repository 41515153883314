import { Component, OnInit, Input, QueryList, ViewChildren, ViewChild, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-multiselect-dropdown',
  templateUrl: './multiselect-dropdown.component.html',
  styleUrls: ['./multiselect-dropdown.component.css']
})
export class MultiselectDropdownComponent implements OnInit {
  @Input() multiSelectDropDownParams: any;
  @Input() dropdownList = [];
  @Input() selectedItems = [];
  @Input() dropdownSettings = [];
  @Output() getSelectedItems = new EventEmitter<any>();

  constructor() { 
  }

  ngOnInit() {
  }

  /**
   * Get selected multi select list
   * @param item item
   * @param formControlName formControlName
   */
  onItemSelect(item: any, formControlName: string) {
    this.getSelectedItems.next(this.selectedItems);
  }

  /**
   * Remove multi select
   * @param item item
   * @param formControlName formControlName
   */
  OnItemDeSelect(item: any, formControlName: string) {
    this.getSelectedItems.next(this.selectedItems);
  }

  /**
   * On select all multi select dropdown values
   * @param items items
   * @param formControlName formControlName
   */
  onSelectAll(items: any, formControlName: string) {
    this.selectedItems = [];
    for (var i = 0; i < items.length; i++) {
      if (formControlName === 'outlet_services') {
        this.selectedItems.push({ id: items[i]['id'], itemName: items[i]['itemName'], service_category: items[i]['service_category'] });
      } else if (formControlName === 'shift') {
        this.selectedItems.push({ id: items[i]['id'], itemName: items[i]['itemName'], schedule: items[i]['schedule'] });
      } else {
        this.selectedItems.push({ id: items[i]['id'], itemName: items[i]['itemName']});
      }
    }
    this.getSelectedItems.next(this.selectedItems);
  }

  /**
   * Empty the multiselect dropdown value
   * @param items items
   * @param formControlName formControlName
   */
  onDeSelectAll(items: any, formControlName: string) {
    this.selectedItems = [];
    this.getSelectedItems.next(this.selectedItems);
  }

  /**
   * On filter select all multi select dropdown values 
   * @param items items
   * @param formControlName formControlName
   */

  onFilterSelectAll(items: any, formControlName: string) {
    this.selectedItems = [];
    for (var i = 0; i < items.length; i++) {
      if (formControlName === 'outlet_services') {
        this.selectedItems.push({ id: items[i]['id'], itemName: items[i]['itemName'], service_category: items[i]['service_category'] });
      } else if (formControlName === 'shift') {
        this.selectedItems.push({ id: items[i]['id'], itemName: items[i]['itemName'], schedule: items[i]['schedule'] });
      } else {
        this.selectedItems.push({ id: items[i]['id'], itemName: items[i]['itemName']});
      }
    }
    this.getSelectedItems.next(this.selectedItems);
  }

  /**
   * Empty the multiselect dropdown filtered value
   * @param items items
   * @param formControlName formControlName
   */
  onFilterDeSelectAll(items: any, formControlName: string) {
    this.selectedItems = [];
    this.getSelectedItems.next(this.selectedItems);
  }

}
