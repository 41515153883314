import { Component, OnInit, Input, QueryList, ViewChildren, ViewChild, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.css']
})
export class TextareaComponent implements OnInit {
  @Input() textareaParams: any;
  @Output() getTextareaValueOnChange = new EventEmitter<string>();

  constructor() { }

  ngOnInit() {
  }

  onChangeTextarea(value) {
    this.getTextareaValueOnChange.next(value);
  }

}
