import { Injectable } from '@angular/core';
import { Headers, Http, RequestOptions} from '@angular/http';
import { ApiService } from '../../../common/services/rest-api/api.service';
import 'rxjs/add/operator/toPromise';
import 'rxjs/Rx';

@Injectable()
export class PermissionRestService {

  constructor(private http: Http, private apiService: ApiService) {
  }

  /**
   * create new Permission
   * @param apiUrl apiUrl
   * @param data  requested data
   */
  public createPermission(apiUrl: string, data: any) {
    return this.apiService.postApi(apiUrl, data)
      .toPromise()
      .then((res) => res);
  }

  /**
   * Update Permission data by id
   * @param apiUrl App Url
   * @param data  requested data
   */
  public updatePermission(apiUrl: string, data: any) {
    return this.apiService.putApi(apiUrl, data)
      .toPromise()
      .then((res) => res);
  }


  /**
   * Get Permission by id
   * @param apiUrl app url
   * @param data requested data
   */
  public getPermissionById(apiUrl: string) {
    return this.apiService.getApi(apiUrl)
      .toPromise()
      .then((res) => res);
  }

  /**
   * Function to get all roles
   */

  public getRolesList(apiUrl: string) {
    return this.apiService.getApi(apiUrl)
      .toPromise()
      .then((res) => res);
  }
  /**
   * Function to get Module Actions List
   */

  public getModulesActionsList(apiUrl: string) {
    return this.apiService.getApi(apiUrl)
      .toPromise()
      .then((res) => res);
  }



  /**
   * Function delete Permission by id
   * @param id id parm for single data delete
   */

  public delete(apiUrl: string) {
    return this.apiService.delete(apiUrl)
      .toPromise()
      .then((res) => res);
  }

}
