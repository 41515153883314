import { Component, Input } from '@angular/core';
import { AbstractControlDirective, AbstractControl } from '@angular/forms';

@Component({
  selector: 'show-errors',
  template: `
   <ul *ngIf="shouldShowErrors()" class="validation-errors">
     <li style="color: red">{{getError()}}</li>
   </ul>
 `,
})


export class ShowErrorsComponent {
  private static readonly errorMessages = {
    required: (params) => 'This field is required',
    minlength: (params) => 'Should be of minimum ' + params.requiredLength + ' Characters',
    maxlength: (params) => 'Should not be greater than ' + params.requiredLength + ' Characters',
    pattern: (params) => 'Should be valid',
    vaildEmail: (params) => params.message,
    numbersOnly: (params) => params.message,
    validPhone: (params) => params.message,
    address1: (params) => params.message,
    address2: (params) => params.message,
    city: (params) => params.message,
    country: (params) => params.message,
    postalCode: (params) => params.message,
    onlyAlphabets: (params) => params.message,
    alphaNumeric: (params) => params.message,
    alphaNumericWithoutSpace: (params) => params.message,
    validPassword: (params) => params.message,
    validName: (params) => params.message,
    vaildNumber: (params) => params.message,
    validOutletCategory: (params) => params.message,
    validOutletService: (params) => params.message,
    validSelectZoneName: (params) => params.message,
    validLocation: (params) => params.message,
    scheduleName: (params) => params.message,
    outletstartTime: (params) => params.message,
    outletendTime: (params) => params.message,
    validServiceType: (params) => params.message,
    validServicePrice: (params) => params.message,
    validServiceTimeDuration: (params) => params.message,
    vaildPhone: (params) => params.message,
    vaildDate: (params) => params.message,
    validQuantity: (params) => params.message,
    emailAlreadyExist: (params) => 'Email already exist!',
    selectOutletWorker: (params) => 'Please select atleast one outlet worker type',
    selectOutletDays: (params) => 'Please select atleast one day(s)',
    validScheduleTimeDays: (params) => 'Please provide valid time for atleast one day(s)',
    selectStartEndTime: (params) => 'Please select atleast one day time',
    selectWorkerType: (params) => 'Please select atleast one worker type',
    selectNominee: (params) => 'Please select nominee',
    regFeeCustomValidation: (params) => params.message,
    validNumberSeat: (params) => params.message,
    selectRangeType: (params) => 'Please select time as per the shedule timing',
    priceEmptyValidate: (params) => 'Please provide Service Charges',
    priceValidate: (params) => 'Service Charges should be greater than zero',
    maxPriceValidate: (params) => 'Service Charges should not be greater than 5000',
    numberSeat: (params) => 'Please provide Number of seats',
    numberOfSeatGreateThanZero: (params) => 'Number of seats should be greater than zero',
    priceNumeric: (params) => 'Price should be numeric only',
    noOfSeatsNumeric: (params) => 'Number of seats should be numeric only',
    selectTime: (params) => 'Please enter start time and end time',
    selectValidTime:  (params) => 'End Time should be greater than start time',
    selectEndRangeType: (params) => 'Please select time as per the schedule timing',
    isRequired: (params) => params.message,
    selectValidStartTime:  (params) => 'Shift start time should be greater than schedule start time',
    selectValidEndTime:  (params) => 'Shift end time should be less than schedule end time',
    vaildCancellationTime: (params) => 'Please provide the cancellation hours',
    ValidPaidCancellationChanrges: (params) => 'Paid service charges should not be empty',
    serviceScheduleStartDate: (params) => 'Please provide Start Date',
    serviceScheduleEndDate: (params) => 'Please provide End Date',
    validPassDate: (params) => params.message,
    passDate: (params) => 'Please provide Date',
    startAge: (params) => 'Please provide min Age',
    endAge: (params) => 'Please provide max Age',
    selectGender: (params) => 'Please provide gender detail',
    selectCustomer: (params) => 'Please select customers',
    paidChargesNumeric: (params) => 'Paid service charges should be numeric only',
    paidChargesPercantage: (params) => 'Paid service charges should not be geater than 100',
    freeChargesNumeric: (params) => 'Charges should be numeric only',
    selectValidDate: (params) => 'Please provide valid Date',
    emptyDate: (params) => 'Please provide Date Of Birth',
    selectPassRule: (params) => 'Please select Pass Rule',
    bookingRuleLimit: (params) => 'FAQ should not be geater than 1000',
    selectValidEndDate:  (params) => 'End Date should be greater than start date',
    selectHoursOperstion:  (params) => 'Please provide hours of operation',
    noOfSeats: (params) => 'No of seats should not be greater than 300',
    selectValidEvent: (params) => 'Please select valid Event',
    selectValidOutlet: (params) => 'Please select valid Location',
    selectValidState: (params) => 'Please select valid State',
    selectOutlet: (params) => 'Please select Location',
    selectState: (params) => 'Please select State',
    selectEmployeeService: (params) => 'Please select atleast one service',
    selectLocationRoom: (params) => 'Please provide atleast one instance room',
    selectValidNominatedBy: (params) => 'Please select valid Nominated By',
    validCbdAmount:(params) => 'Please provide CBD Amount',
    cbdChargesNumeric: (params) => 'Please provide valid CBD charges',
    selectEmailNotificationType: (params) => 'Please select atleast one notification type',
    selectEmailNotificationSubject: (params) => 'Please provide subject for email',
    selectEmailNotificationBody: (params) => 'Please provide body for email',
    selectAppNotificationBody: (params) => 'Please provide body for app',
    emailNotificationAge: (params) => 'Age field must be a number',
    selectedEmailNotificationAgerange: (params) => 'Please provide Age range',
    selectedEmailNotificationAgeGreater: (params) => 'Age to should be greater than age from',
    validSubstitute: (params) => 'Please select substitute',
    validPriceLength:(params) => 'Should not be greater than 5 digits',
    aboutUsLimit: (params) => 'Should not be greater than 500 Characters',
    vaildColonCancellationTime: (params) => 'Colon not allowed as first character',
    selectService: (params) => 'Please select the Service',
    selectProvider: (params) => 'Please select Provider',
    selectMember: (params) => 'Please select the Member',
    selectValidService: (params) => 'Please select valid Service',
    selectValidProvider: (params) => 'Please select valid Provider',
    selectValidMember: (params) => 'Please select valid Member',
    validServiceBreakTime: (params) => 'Please provide Service Break Time',
    validTimeDuration: (params) => 'Please provide Service Time Duration',
    validNumericTimeDuration: (params) => 'Time Duration Should be greater than zero',
    expirationDate: (params) => 'Please provide Expiration Date',
    requiredCardName: (params) => 'Please provide card holder name',
    requiredCardNumber: (params) => 'Please provide card number',
    requiredCvv: (params) => 'Please provide cvv',
    validFreeTherapy: (params) => 'Please provide Free Therapy',
    validFreeGuestPass: (params) => 'Please provide Free Guest Pass',
    requiredRegFee: (params) => 'Please provide Initiation Fee',
    requiredMonthlyFee: (params) => 'Please provide Monthly Fee',
    requiredYearlyFee: (params) => 'Please provide Annual Fee',
    selectItem: (params) => 'Please select Item',
    selectGuest: (params) => 'Please select the Guest',
    RequiredGuest: (params) => 'Please add Guest',
    matchPassword: (params) => 'New and confirm password is not matched',
    validEventImage: (params) => 'Please upload atleast one image',
    validEventTime: (params) => 'Please select Event Time',
    validExpiryDate: (params) => 'Please provide Event Expiry Date',
    validEventCompareDate: (params) => 'Expiry date should be greater than event date',
    validServiceSchedules: (params) => 'Please provide atleast one schedule name(s)',
    pausedMonth: (params) => 'Please select Paused Month',
    validUserLocations: (params) => params.message,
    scheduleAlreadyExist: (params) => 'Room Schedule already exist for the selected day.',
    effectiveToShouldBeGreater: (params) => 'Effective to should be greater than effective from at schedule.',
    serviceScheduleStartTime: (params) => 'Please provide Start Time',
    serviceScheduleEndTime: (params) => 'Please provide End Time',
    couponCodeExpired: (params) => 'Coupon Code has been expired!',
};

  @Input()
  private control: AbstractControlDirective | AbstractControl;
  shouldShowErrors(): boolean {
    return this.control &&
      this.control.errors &&
      (this.control.dirty || this.control.touched);
  }

  listOfErrors(): string[] {
    return Object.keys(this.control.errors)
      .map(field => this.getMessage(field, this.control.errors[field], this.control));
  }

  getError(): string {
    var errors = Object.keys(this.control.errors)
      .map(field => this.getMessage(field, this.control.errors[field], this.control));
    return errors[0];
  }

  private getMessage(type: string, params: any, control: any) {
    var fname = this.getControlName(control);
    fname = fname.replace("_", " ").replace(" id", "").toLowerCase();
    fname = fname.replace(/\b\w/g, l => l.toUpperCase())
    var msg = ShowErrorsComponent.errorMessages[type](params);
    return msg;
  }

  getControlName(c: AbstractControl): string | null {
    const formGroup = c.parent.controls;
    return Object.keys(formGroup).find(name => c === formGroup[name]) || null;
  }

}