import { Component, OnInit, Input, QueryList, ViewChildren, ViewChild, Output, EventEmitter } from '@angular/core';
import { CompleterCmp, CompleterData, CompleterService, CompleterItem, RemoteData } from 'ng2-completer';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-predictive-search-dropdown',
  templateUrl: './predictive-search-dropdown.component.html',
  styleUrls: ['./predictive-search-dropdown.component.css']
})
export class PredictiveSearchDropdownComponent implements OnInit {
  @Input() dropDownParams: any;
  @Input() dataSourceList = [];
  dataSource: any;
  public isOpen: boolean = false;
  @Output() getSelectedValue = new EventEmitter<string>();
  public onOpened(isOpen: boolean) {
    this.isOpen = isOpen;
  }
  constructor(
    private completerService: CompleterService
  ) { }

  ngOnInit() {
    this.dataSource = this.completerService.local(
      this.dataSourceList,
      this.dropDownParams.dataSourceListValueParam,
      this.dropDownParams.dataSourceListValueParam
    );
  }

  onSelect(selected: CompleterItem) {
    if (selected) {
      if (selected.originalObject) {
        this.getSelectedValue.next(selected.originalObject);
      } else {
        this.getSelectedValue.next('');
      }
    } else {
      this.getSelectedValue.next('');
    }
  }

  onBlur(event){
    if(event) {
      const eventtest = this.dataSourceList.filter(x => x.name === event);
      if(eventtest.length > 0) {
       } else{
        this.getSelectedValue.next('0');
      }
    } else {
      this.getSelectedValue.next('');
    }
  }
}
