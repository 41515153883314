import { Component, OnInit, Input, QueryList, ViewChildren, ViewChild, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-input-field',
  templateUrl: './input-field.component.html',
  styleUrls: ['./input-field.component.css']
})
export class InputFieldComponent implements OnInit {
  @Input() inputFieldParams: any;
  @Output() getInputValueOnChange = new EventEmitter<string>();

  constructor() { }

  ngOnInit() {
  }

  onChangeInput(inputFieldValue) {
    this.getInputValueOnChange.next(inputFieldValue);
  }

}
