import { Injectable } from '@angular/core';
import { Headers, Http, RequestOptions} from '@angular/http';
import { ApiService } from '../../../common/services/rest-api/api.service';
import 'rxjs/add/operator/toPromise';
import 'rxjs/Rx';

@Injectable()
export class AuthRestService {

  constructor(private apiService: ApiService) {
  }

  /**
   * Function to login
   * @param data request data
   */
  public login(apiUrl: string, data: any) {
    return this.apiService.postApiWitoutToken(apiUrl, data)
      .toPromise()
      .then((res: any) => res);
  }

  /**
   * Get Company outlet list
   * @param apiUrl api url
   */
  public getCompanyOutletList(apiUrl: string) {
    return this.apiService.getApi(apiUrl)
      .toPromise()
      .then((res: any) => res);
  }


  /**
   * Get nominated customer list
   * @param apiUrl api url
   */
  public getNominatedCustomerList(apiUrl: string) {
    return this.apiService.getApi(apiUrl)
      .toPromise()
      .then((res: any) => res);
  }

  /**
   * Get nominated customer list
   * @param apiUrl api url
   */
  public getEventList(apiUrl: string) {
    return this.apiService.getApi(apiUrl)
      .toPromise()
      .then((res: any) => res);
  }

  /**
   * Get all state list
   * @param apiUrl api url
   */
  public getAllStateList(apiUrl: string) {
    return this.apiService.getApi(apiUrl)
      .toPromise()
      .then((res) => res);
  }

  /**
   * Get room list
   * @param apiUrl api url
   */
  public getRoomList(apiUrl: string) {
    return this.apiService.getApi(apiUrl)
      .toPromise()
      .then((res: any) => res);
  }

}
