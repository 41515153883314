import {Constants } from '../../common/Constants';

export class OutletServiceScheduleApi {
    static getOutletScheduleListByOutletIdUrl = Constants.baseUrl + 'outlet-schedule-list/';
    static getOutletServiceListByOutletIdUrl = Constants.baseUrl + 'service-list/';
    static getOutletRoomStaggeringServiceListByOutletIdUrl = Constants.baseUrl + 'room-staggering-service-list/';
    static getdefaultScheduleServiceUrl = Constants.baseUrl + 'default-schedule-service/';
    static getRoomStaggeringdefaultScheduleServiceUrl = Constants.baseUrl + 'room-staggering-default-schedule-service/';
    static getShiftListByScheduleIdUrl = Constants.baseUrl + 'shift-list-by-schedule/';
    static getEmployeeListByShiftUrl = Constants.baseUrl + 'shift-employee/';
    static createServiceScheduleUrl = Constants.baseUrl + 'service-schedule-create/';
    static getServiceScheduleListWithPaginationUrl = Constants.baseUrl + 'schedule-service-list/';
    static getServiceScheduleByIdUrl = Constants.baseUrl + 'service-schedule/';
    static getScheduleServiceDetailByIdOnClick = Constants.baseUrl + 'schedule-service/';
    static getScheduleServiceByOutletService = Constants.baseUrl + 'schedule-service-by-outlet-service/';
    static getServiceScheduleListWithPaginationByOutletIdUrl = Constants.baseUrl + 'service-schedule-list/';
    static scheduleServiceCreateUrl = Constants.baseUrl + 'schedule-service-create/';
    static updateSheduleServiceUrl = Constants.baseUrl + 'update-shedule-service/';
    static deleteScheduleServiceUrl = Constants.baseUrl + 'delete-schedule-service/';
    static scheduleServicesByDateUrl = Constants.baseUrl + 'schedule-services-by-date/';
    static slotListByOutletUrl = Constants.baseUrl + 'slot-list-by-outlet/';
    static getOutletServiceEmployeeUrl = Constants.baseUrl + 'outlet-service-employee/';
    static getScheduleServiceByOutletServiceUrl = Constants.baseUrl + 'schedule-service-by-outlet-service/';
    static deleteServiceScheduleUrl = Constants.baseUrl + 'service-schedule/';
    static getEmployeeListUrl = Constants.baseUrl + 'employee-list-by-outlet/';
    static getScheduleServiceRepetitionBookingUrl = Constants.baseUrl + 'get-schedule-service-repetition-booking/';
    static serviceListByOutletDateUrl = Constants.baseUrl + 'outlet-services-by-date/';
    static slotListByOutletDateUrl = Constants.baseUrl + 'outlet-services-slot-by-date/';
    static employeeSlotListByServiceUrl = Constants.baseUrl + 'employee-scheduling-calendar/';
    static employeeScheduleByDateUrl = Constants.baseUrl + 'web/employee-schedule-by-date/';
    static getOutletServiceInstanceListByIdUrl = Constants.baseUrl + 'outlet-service-instance/';
    static getOutletServiceRoomStaggeringListByIdUrl = Constants.baseUrl + 'outlet-service-room-staggering-list/';
    static createRoomStaggeringUrl = Constants.baseUrl + 'room-staggering/';
    static checkScheduleExistUrl = Constants.baseUrl + 'check-room-staggering-schedule-already-exist/';

}

