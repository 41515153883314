import { Injectable } from '@angular/core';
import { Headers, Http, RequestOptions} from '@angular/http';
import { ApiService } from '../../../common/services/rest-api/api.service';
import 'rxjs/add/operator/toPromise';
import 'rxjs/Rx';

@Injectable()
export class OutletScheduleRestService {

  constructor(private apiService: ApiService) {
  };

  public createOutletSchedule(apiUrl: string, data: any) {
    return this.apiService.postApi(apiUrl, data)
      .toPromise()
      .then((res) => res);
  }

  /**
   * Update Outlet Schedule data by id
   * @param apiUrl App Url
   * @param data  requested data
   */
  public updateOutletSchedule(apiUrl: string, data: any) {
    return this.apiService.putApi(apiUrl, data)
      .toPromise()
      .then((res) => res);
  }


  /**
   * Get Outlet Schedule by id
   * @param apiUrl app url
   * @param data requested data
   */
  public getOutletScheduleById(apiUrl: string) {
    return this.apiService.getApi(apiUrl)
      .toPromise()
      .then((res) => res);
  }

  /**
   * Function to get all Outlet Schedules
   */

  public getOutletScheduleList(apiUrl: string) {
    return this.apiService.getApi(apiUrl)
      .toPromise()
      .then((res) => res);
  }

  /**
   * Function delete Outlet Schedule by id
   * @param id id parm for single data delete
   */

  public delete(apiUrl: string) {
    return this.apiService.delete(apiUrl)
      .toPromise()
      .then((res) => res);
  }

  /**
   * Function to get all workertype
   */

  public getWorkerTypeList(apiUrl: string) {
    return this.apiService.getApi(apiUrl)
      .toPromise()
      .then((res) => res);
  }

}
