import { Injectable } from '@angular/core';
import { Headers, Http, RequestOptions} from '@angular/http';
import { ApiService } from '../rest-api/api.service';
import 'rxjs/add/operator/toPromise';
import 'rxjs/Rx';

@Injectable()
export class RoleRestService {

  constructor(private apiService: ApiService) {
  };

  /**
   * 
   * @param apiUrl api url
   * @param data request data
   */
  public createRole(apiUrl: string, data: any) {
    return this.apiService.postApi(apiUrl, data)
      .toPromise()
      .then((res) => res);
  }

  /**
   * Update outlet data by id
   * @param apiUrl api url
   * @param data request data
   */
  public updateRole(apiUrl: string, data: any) {
    return this.apiService.putApi(apiUrl, data)
      .toPromise()
      .then((res) => res);
  }


  /**
   * Get outlet  by id
   * @param apiUrl api url
   * @param data request data
   */
  public getRoleById(apiUrl: string) {
    return this.apiService.getApi(apiUrl)
      .toPromise()
      .then((res) => res);
  }

  /**
   * Function to get all outlet
   */

  public getRoleList(apiUrl: string) {
    return this.apiService.getApi(apiUrl)
      .toPromise()
      .then((res) => res);
  }


  /**
   * Function delete role  by id
   * @param id role id
   */

  public delete(apiUrl: string) {
    return this.apiService.delete(apiUrl)
      .toPromise()
      .then((res) => res);
  }

  /**
   * Function to get all workertype
   */

  public getWorkerTypeList(apiUrl: string) {
    return this.apiService.getApi(apiUrl)
      .toPromise()
      .then((res) => res);
  }

  /**
   * Get Company outlet list
   * @param apiUrl api url
   */
  public getCompanyOutletList(apiUrl: string) {
    return this.apiService.getApi(apiUrl)
      .toPromise()
      .then((res) => res);
  }

}
