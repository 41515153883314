import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { OutletServiceScheduleRestService } from './outlet-service-schedule-rest.service';
import 'rxjs/add/operator/toPromise';
import 'rxjs/Rx';

@Injectable()
export class OutletServiceScheduleService {

 constructor(private outletServiceScheduleRestService: OutletServiceScheduleRestService, private router: Router) {
  }

  /**
   * create new outlet service schedule
   * @param apiUrl requested api url
   * @param data requested data
   */
  public createOutletServiceSchedule(apiUrl: string, data: any) {
    return this.outletServiceScheduleRestService.createOutletServiceSchedule(apiUrl, data)
      .then((res: any) => res);
  }

  /**
   * Function to check email exist or not
   * @param apiUrl api url
   * @param data request data
   */
  public checkScheduleExist(apiUrl: string, data: any) {
    return this.outletServiceScheduleRestService.checkScheduleExist(apiUrl, data)
      .then((res: any) => res);
  }

  /**
   * Update Outlet Service Schedule by id.
   * @param apiUrl requested api url
   * @param data requested data
   */
  public updateOutletServiceSchedule(apiUrl: string, data: any) {
    return this.outletServiceScheduleRestService.updateOutletServiceSchedule(apiUrl, data)
      .then((res: any) => res);
  }

  /**
   * get shift employee by Schedule id.
   * @param apiUrl requested api url
   * @param data requested data
   */
  public getShiftEmployeeBySchedule(apiUrl: string, data: any) {
    return this.outletServiceScheduleRestService.getShiftEmployeeBySchedule(apiUrl, data)
      .then((res: any) => res);
  }

  /**
   * Get outlet Service Schedule by id
   * @param apiUrl requested api url
   */
  public getOutletServiceScheduleById(apiUrl: string) {
    return this.outletServiceScheduleRestService.getOutletServiceScheduleById(apiUrl)
      .then((res: any) => res);
  }

  /**
   * Get outlet Services by id
   * @param apiUrl requested api url
   */
  public getOutletServiceById(apiUrl: string) {
    return this.outletServiceScheduleRestService.getOutletServiceById(apiUrl)
      .then((res: any) => res);
  }

  /**
   * Get outlet Services by id
   * @param apiUrl requested api url
   */
     public getOutletServiceInstanceById(apiUrl: string) {
      return this.outletServiceScheduleRestService.getOutletServiceInstanceById(apiUrl)
        .then((res: any) => res);
    }

  /**
   * Get Service Slots list by serviceId.
   * @param apiUrl requested api url
   * @param data requested data
   */
  public getServiceSlotsByServiceId(apiUrl: string, data: any) {
    return this.outletServiceScheduleRestService.getServiceSlotsByServiceId(apiUrl, data)
      .then((res: any) => res);
  }

  /**
   * Get shift by scheduleId
   * @param apiUrl requested api url
   */
  public getShiftByScheduleId(apiUrl: string) {
    return this.outletServiceScheduleRestService.getOutletServiceById(apiUrl)
      .then((res: any) => res);
  }

  /**
   * Get outlet employee list by shift id
   * @param apiUrl requested api url
   */
  public getEmployeeListByShiftId(apiUrl: string) {
    return this.outletServiceScheduleRestService.getEmployeeListByShiftId(apiUrl)
      .then((res: any) => res);
  }


  /**
   * Function to get all Outlet Service Schedules
   * @param apiUrl  requested api url
   */
  public getOutletServiceScheduleList(apiUrl: string) {
    return this.outletServiceScheduleRestService.getOutletServiceScheduleList(apiUrl)
      .then((res: any) => res);
  }

  /**
   * Get Outlet Service Schedule by id
   * @param apiUrl requested api url
   */
  public deleteServiceScheduleById(apiUrl: string) {
    return this.outletServiceScheduleRestService.delete(apiUrl)
      .then((res: any) => res);
  }

  /**
   * Get Outlet Service Schedule repitation by id
   * @param apiUrl requested api url
   */
  public deleteRepitationByRepId(apiUrl: string) {
    return this.outletServiceScheduleRestService.deleteRepitationByRepId(apiUrl)
      .then((res: any) => res);
  }

}
