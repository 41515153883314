import { Component, OnInit, Input, QueryList, ViewChildren, ViewChild, Output, EventEmitter } from '@angular/core';
import { IMyDpOptions, IMyDateModel } from 'mydatepicker';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.css']
})
export class DatePickerComponent implements OnInit {
  @Input() datePickerParams: any;
  @Input() selectedDate = [];
  @Output() getSelectedDate = new EventEmitter<any>();
  IMyDpOptions: any;

  constructor() { }

  ngOnInit() {
    if (this.datePickerParams.showFutureDate) {
      this.IMyDpOptions = {
        firstDayOfWeek: 'su',
        dateFormat: 'mm/dd/yyyy',
        minYear: 1800,
        maxYear: 2200,
        indicateInvalidDate: true,
        markCurrentDay: true,
        showSelectorArrow: false,
        showClearDateBtn: true,
        //disableSince: { year: (new Date()).getFullYear(), month: ((new Date()).getMonth() + 1), day: ((new Date()).getDate() - 1) }
      };
    } else if (this.datePickerParams.dateFormatChange) {
      this.IMyDpOptions = {
        firstDayOfWeek: 'su',
        dateFormat: 'dd/mm',
        minYear: (new Date()).getFullYear(),
        maxYear: 2200,
        indicateInvalidDate: true,
        markCurrentDay: true,
        showSelectorArrow: false,
        showClearDateBtn: true,
        yearSelector: false,
        disableUntil: { year: (new Date()).getFullYear(), month: ((new Date()).getMonth() + 1), day: ((new Date()).getDate() - 1) }
      };
    } else if (this.datePickerParams.disableWeekdays) {
      this.IMyDpOptions = {
        firstDayOfWeek: 'su',
        dateFormat: 'mm/dd/yyyy',
        minYear: 1800,
        maxYear: 2200,
        indicateInvalidDate: true,
        markCurrentDay: true,
        showSelectorArrow: false,
        showClearDateBtn: true,
        disableWeekdays: this.datePickerParams.disableWeekdays,
        disableUntil: { year: (new Date()).getFullYear(), month: ((new Date()).getMonth() + 1), day: ((new Date()).getDate() - 1) }
      };
    } else if (!this.datePickerParams.showFutureDate) {
      this.IMyDpOptions = {
        firstDayOfWeek: 'su',
        dateFormat: 'mm/dd/yyyy',
        minYear: 1800,
        maxYear: 2200,
        indicateInvalidDate: true,
        markCurrentDay: true,
        showSelectorArrow: false,
        showClearDateBtn: true,
        showTodayBtn: false,
        disableUntil: { year: (new Date()).getFullYear(), month: ((new Date()).getMonth() + 1), day: ((new Date()).getDate() - 1) }
      };
    } else {
      this.IMyDpOptions = {
        firstDayOfWeek: 'su',
        dateFormat: 'mm/dd/yyyy',
        minYear: 1800,
        maxYear: 2200,
        indicateInvalidDate: true,
        markCurrentDay: true,
        showSelectorArrow: false,
        showClearDateBtn: true,
        showTodayBtn: false,
        // disableUntil: { year: (new Date()).getFullYear(), month: ((new Date()).getMonth() + 1), day: ((new Date()).getDate() + 1) }
      };
    }
  }

  /**
   * Get selected multi select list
   * @param item  item
   * @param formControlName  form control name
   */
  onDateChanged(event: IMyDateModel) {
    // event properties are: event.date, event.jsdate, event.formatted and event.epoc
    this.getSelectedDate.next(event.date);
  }

  /**
   * Validate date on blur
   * @param event event
   */
  onDateBlur(event) {
    // console.log(event);
    const isValid = this.checkDateFormat(event.value);
    if (isValid) {
      const dateArray = event.value.split('/');
      const day = '' + dateArray[1];
      const month = '' + dateArray[0];
      const year = (dateArray[2]) ? dateArray[2] : (new Date()).getFullYear();
      const updateDate = [day, month, year].join('/');
      this.getSelectedDate.next(this.convertDateToObject(updateDate));
    } else if (event.value) {
      this.getSelectedDate.next(isValid);
    } else {
      this.getSelectedDate.next('empty');
    }
  }

  /**
   * Validate date form
   * @param dateString dateString
   */
  checkDateFormat(dateString) {
    if (dateString) {
      const dateArray = dateString.split('/');
      let day = '' + dateArray[1];
      let month = '' + dateArray[0];
      const year = (dateArray[2]) ? dateArray[2] : (new Date()).getFullYear();
      if (day.length < 2) day = '0' + day;
      if (month.length < 2) month = '0' + month;
      if (day === 'undefined' || month === 'undefined' || year === 'undefined') {
        return false;
      }
      if (day === 'NaN' || month === 'NaN' || year.toString() === 'NaN') {
        return false;
      } else {
        return [day, month, year].join('/');
      }
    }
  }

  /**
   * Following function return date string in object format
   * @param strDate : date will accepted as a string format.
   */
  convertDateToObject(strDate: string) {
    if (strDate != null && strDate !== '' && strDate !== undefined) {
      const objDate = strDate.split('/');
      return {
        year: parseInt(objDate[2], 10),
        month: parseInt(objDate[1], 10),
        day: parseInt(objDate[0], 10)
      };
    } else {
      return null;
    }
  }

}
