import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { HeaderComponent } from './shared/components/header/header.component';
import { FooterComponent } from './shared/components/footer/footer.component';
import { SidebarComponent } from './shared/components/sidebar/sidebar.component';
import { AuthGuardService } from './common/services/auth-guard.service';
import { NotFoundComponent } from './shared/components/not-found/not-found.component';
import { ComingSoonComponent } from './shared/components/coming-soon/coming-soon.component';

const routes: Routes = [
    { path: '', redirectTo: '/login', pathMatch: 'full' },
    {
        path: '', children: [
            { path: '', component: HeaderComponent, outlet: 'header' },
            { path: '', component: FooterComponent, outlet: 'footer' },
            { path: '', loadChildren: './modules/auth/auth.module#AuthModule' }
        ],
    },
    {
        path: '', children: [
            { path: '', component: HeaderComponent, outlet: 'header' },
            { path: '', component: FooterComponent, outlet: 'footer' },
            { path: '', component: SidebarComponent, outlet: 'sidebar' },
            { path: 'dashboard', loadChildren: './modules/dashboard/dashboard.module#DashboardModule', canActivate: [AuthGuardService] },
            { path: 'user', loadChildren: './modules/user/user.module#UserModule', canActivate: [AuthGuardService] },
            { path: 'zone', loadChildren: './modules/zone/zone.module#ZoneModule', canActivate: [AuthGuardService] },
            { path: 'service-category',
            loadChildren: './modules/service-category/service-category.module#ServiceCategoryModule', canActivate: [AuthGuardService] },
            { path: 'outlet', loadChildren: './modules/outlet/outlet.module#OutletModule', canActivate: [AuthGuardService] },
            { path: 'services', loadChildren: './modules/services/services.module#ServicesModule', canActivate: [AuthGuardService] },
            { path: 'permission',
            loadChildren: './modules/permission/permission.module#PermissionModule', canActivate: [AuthGuardService] },
            { path: 'outlet-shifts', loadChildren: './modules/outlet-shifts/outlet-shifts.module#OutletShiftsModule',
            canActivate: [AuthGuardService] },
            { path: 'outlet-service-schedule',
            loadChildren: './modules/outlet-service-schedule/outlet-service-schedule.module#OutletServiceScheduleModule',
            canActivate: [AuthGuardService] },
            { path: 'outlet-room-staggering',
            loadChildren: './modules/outlet-room-staggering/outlet-room-staggering.module#OutletRoomStaggeringModule',
            canActivate: [AuthGuardService] },
            { path: 'outlet-schedule',
            loadChildren: './modules/outlet-schedule/outlet-schedule.module#OutletScheduleModule',
            canActivate: [AuthGuardService] },
            { path: 'role', loadChildren: './modules/role/role.module#RoleModule', canActivate: [AuthGuardService] },
            { path: 'coming-soon', component: ComingSoonComponent,
             canActivate: [AuthGuardService] },
            { path: 'outlet-room',
             loadChildren: './modules/outlet-room/outlet-room.module#OutletRoomModule',
             canActivate: [AuthGuardService] },
            { path: 'app-message', loadChildren: './modules/app-message/app-message.module#AppMessageModule', canActivate: [AuthGuardService] },
            { path: 'inventory', loadChildren: './modules/inventory/inventory.module#InventoryModule', canActivate: [AuthGuardService] },
            { path: 'outlet-service-booking', loadChildren: './modules/outlet-service-booking/outlet-service-booking.module#OutletServiceBookingModule', canActivate: [AuthGuardService] },
            { path: 'frontdesk-dashboard', loadChildren: './modules/frontdesk-dashboard/frontdesk-dashboard.module#FrontdeskDashboardModule', canActivate: [AuthGuardService] },
            { path: 'admin-check-in', loadChildren: './modules/admin-check-in/admin-check-in.module#AdminCheckInModule', canActivate: [AuthGuardService] },
            { path: 'report', loadChildren: './modules/report/report.module#ReportModule', canActivate: [AuthGuardService] },
            { path: 'events', loadChildren: './modules/event/event.module#EventModule', canActivate: [AuthGuardService] },
            { path: 'holidays', loadChildren: './modules/holiday/holiday.module#HolidayModule', canActivate: [AuthGuardService] },
            { path: 'coupons', loadChildren: './modules/coupons/coupons.module#CouponsModule', canActivate: [AuthGuardService] },
            { path: 'membership-benefits', loadChildren: './modules/membership-benefits/membership-benefits.module#MembershipBenefitsModule', canActivate: [AuthGuardService] },
            

        ]
    },
    { path: '404', component: NotFoundComponent },
    { path: '**', redirectTo: '/404' },
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
