import { Injectable } from '@angular/core';
import { Headers, Http, RequestOptions} from '@angular/http';
import { ApiService } from '../../../common/services/rest-api/api.service';
import 'rxjs/add/operator/toPromise';
import 'rxjs/Rx';

@Injectable()
export class ServiceCategoryRestService {

  constructor(private http: Http, private apiService: ApiService) {
  }

  /**
   * @param apiUrl apiUrl
   * @param data request data
   */
  public createServiceCategory(apiUrl: string, data: any) {
    return this.apiService.postApi(apiUrl, data)
      .toPromise()
      .then((res) => res);
  }

  /**
   * Update service category data by id
   * @param apiUrl apiUrl parm
   * @param data data
   */
  public updateServiceCategory(apiUrl: string, data: any) {
    return this.apiService.putApi(apiUrl, data)
      .toPromise()
      .then((res) => res);
  }


  /**
   * Get service catgory by id
   * @param apiUrl apiUrl parm
   * @param data data
   */
  public getServiceCategoryById(apiUrl: string) {
    return this.apiService.getApi(apiUrl)
      .toPromise()
      .then((res) => res);
  }

  /**
   * Function to get all service category
   */

  public getServiceCatgoryList(apiUrl: string) {
    return this.apiService.getApi(apiUrl)
      .toPromise()
      .then((res) => res);
  }

  /**
   * Function to get all category type list
   */

  public getCategoryTypeList(apiUrl: string) {
    return this.apiService.getApi(apiUrl)
      .toPromise()
      .then((res) => res);
  }


  /**
   * Function delete service category by id
   * @param id id for delete
   */

  public delete(apiUrl: string) {
    return this.apiService.delete(apiUrl)
      .toPromise()
      .then((res) => res);
  }

}
