import { Injectable } from '@angular/core';
import { Headers, Http, RequestOptions} from '@angular/http';
import { ApiService } from '../../../common/services/rest-api/api.service';
import 'rxjs/add/operator/toPromise';
import 'rxjs/Rx';

@Injectable()
export class ZoneRestService {

  constructor(private http: Http, private apiService: ApiService) {
  }

  /**
   * create new zone
   * @param apiUrl apiUrl
   * @param data  requested data
   */
  public createZone(apiUrl: string, data: any) {
    return this.apiService.postApi(apiUrl, data)
      .toPromise()
      .then((res) => res);
  }

  /**
   * Update zone data by id
   * @param apiUrl App Url
   * @param data  requested data
   */
  public updateZone(apiUrl: string, data: any) {
    return this.apiService.putApi(apiUrl, data)
      .toPromise()
      .then((res) => res);
  }


  /**
   * Get zone by id
   * @param apiUrl app url
   * @param data requested data
   */
  public getZoneById(apiUrl: string) {
    return this.apiService.getApi(apiUrl)
      .toPromise()
      .then((res) => res);
  }

  /**
   * Function to get all zones
   */

  public getZoneList(apiUrl: string) {
    return this.apiService.getApi(apiUrl)
      .toPromise()
      .then((res) => res);
  }


  /**
   * Function delete zone by id
   * @param id id parm for single data delete
   */

  public delete(apiUrl: string) {
    return this.apiService.delete(apiUrl)
      .toPromise()
      .then((res) => res);
  }

}
