import { Component, OnInit, Input, QueryList, ViewChildren, ViewChild, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { EventInput, OptionsInput } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGrigPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction'; // for dateClick
import listPlugin from '@fullcalendar/list'; // for list
import { Router, ActivatedRoute } from '@angular/router';
import { OutletServiceScheduleService } from '../../../common/services/outlet-service-schedule/outlet-service-schedule.service';
import { CurrentUserService } from '../../../common/services/user/current-user.service';
import { UserService } from '../../../common/services/user/user.service';
import { ToastrService } from 'ngx-toastr';
import { OutletServiceScheduleApi } from '../../../modules/outlet-service-schedule/outlet-service-schedule-api';
import { ChangeDateFormatService } from '../../../common/services/date-picker/change-date-format.service';
import { UserApi } from '../../../modules/user/user-api';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { reduce } from 'rxjs/operators';
import { CustomValidators } from '../../../common/directive/custom-validator.directive';
import { ConfirmDialogService } from '../../../shared/components/confirm-dialog/confirm-dialog.service';


@Component({
  selector: 'app-full-calender',
  templateUrl: './full-calender.component.html',
  styleUrls: ['./full-calender.component.css'],
  providers: [OutletServiceScheduleService, ChangeDateFormatService, UserService, ConfirmDialogService]
})

export class FullCalenderComponent implements OnInit {
  @Input() fullCalenderParams: any;

  @Output() getSelectedValue = new EventEmitter<string>();
  calenderForm: FormGroup;
  addSubstituteForm: FormGroup;
  eventsModel: any;
  navLinks: true;
  calendarEvents = [];
  showLoader: boolean;
  options: OptionsInput;
  calendarPlugins = [dayGridPlugin, timeGrigPlugin, interactionPlugin, listPlugin];
  outletScheduleId: any;
  allDay: false;
  serviceList: any = [];
  selectedServiceList = [];
  employeeList: any = [];
  selectedEmployee: any;
  selectedServiceId: any;
  selectedEmployeeId: any;
  serviceId: any;
  employeeId: any;
  eventOpen = false;
  isEmployee = false;
  checkAllEmployee = false;
  customerBookingList: any = [];
  emailFormArray: any = [];
  selectedCustomerArray: any = [];
  substituteList: any = [];
  startDate: number;
  slotName: any;
  selectedSlotId: any;
  selectedsubstituteName: any;
  selectedsubstituteId: any;
  OutletList: any =[];
  selectedOutletId: number;
  employeeServicesList: any =[];
  selectedcustomerList: any;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private outletServiceScheduleService: OutletServiceScheduleService,
    public currentUserService: CurrentUserService,
    public toastrService: ToastrService,
    private changeDateFormatService: ChangeDateFormatService,
    private userService: UserService,
    private sanitizer: DomSanitizer,
    private confirmDialogService: ConfirmDialogService
  ) { }

  ngOnInit() {
    this.calenderForm = new FormGroup({
      customer_id: new FormControl(''),
    });
    this.addSubstituteForm = new FormGroup({
      substitute_name: new FormControl('', [CustomValidators.validSubstitute])
    });

    if (this.fullCalenderParams.type === 'outlet_slots') {
      this.getEmployeeListByOutletId(this.fullCalenderParams.outletId);
      this.getServiceSlotsById(this.fullCalenderParams.outletId, this.serviceId, this.employeeId);
      this.getOutletServiceByOutletId(this.fullCalenderParams.outletId);
    } else if (this.fullCalenderParams.type === 'outlet_service_slots') {
      this.getServiceSlotsById(this.fullCalenderParams.serviceId, this.serviceId, this.employeeId);
    }
    else if (this.fullCalenderParams.type === 'employee_timing_slots') {
      this.getServiceSlotsById(this.fullCalenderParams.outletId, this.fullCalenderParams.serviceId, this.fullCalenderParams.employeeId);
    }
     else if (this.fullCalenderParams.type === 'employee_schedule_calender') {
      this.getOutletList();
      // this.getServiceSlotsById(this.selectedOutletId, this.selectedServiceId, this.selectedEmployeeId);
    }

    this.options = {
      eventLimit: true,
      allDaySlot: false,
      allDayText: 'false',
      firstDay: 1,

      selectable: true,

      // customButtons: {
      //   myCustomButton: {
      //     text: 'custom!',
      //     click: function () {
      //       console.log('clicked the custom button!');
      //     }
      //   }
      // },
      views: {
        agenda: {
          eventLimit: 5,

        }
      },
      header: {
        left: 'prev, title , next', //prev,next today myCustomButton
        center: '',
        right: '', // dayGridMonth,timeGridWeek,timeGridDay,listWeek
      },
      
      eventColor: '#378006'
    };
  }

  /**
   * Get servvice slots by Id
   * @param outletId // OutletId / ServiceId
   */
  getServiceSlotsById(id, serviceId, employeeId) {
    let URL; let requestParams;
    const date = new Date();
    if (this.fullCalenderParams.type === 'outlet_slots') {
      URL = OutletServiceScheduleApi.slotListByOutletDateUrl;
      requestParams = {
        outlet: +id,
        date: date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear(),
        outlet_service_id: serviceId ? serviceId : ''
        //   end_date: date.getMonth() + 1 + '/' + new Date(date.getFullYear(), date.getMonth(), 0).getDate() + '/'
        //  + date.getFullYear()
      };
    } else if (this.fullCalenderParams.type === 'employee_timing_slots') {
      URL = OutletServiceScheduleApi.employeeSlotListByServiceUrl;
      requestParams = {
        outlet: +id,
        service: serviceId,
        employee: employeeId
      };
    }
    else {
      URL = OutletServiceScheduleApi.employeeScheduleByDateUrl;
      requestParams = {
        outlet: id,
        service: serviceId,
        employee: employeeId,
        // start_date:  date.getMonth() + 1 + '/01/' + date.getFullYear(),
        // end_date: date.getMonth() + 1 + '/' + new Date(date.getFullYear(), date.getMonth(), 0).getDate() + '/'
        // + date.getFullYear()
      };
    }
    
    this.showLoader = true;
    this.outletServiceScheduleService.getServiceSlotsByServiceId
      (URL, requestParams).then((response: any) => {
      
        this.showLoader = false;
        if (response.code === 200 && response.message === 'OK') {
          this.calendarEvents = [];
          for (const slot of response.data) {
            const substituteName = slot.subsitute_employee ? slot.subsitute_employee.full_name : '';
            const substituteId = slot.subsitute_employee ? slot.subsitute_employee.id : '';
            const formatedDateTime = this.changeDateFormatService.formatDateForFullCalender(slot.service_date, slot.start_time,'employee_timing_slots');
            const formatedStartDateTime = this.changeDateFormatService.formatDateForFullCalender(slot.service_date, slot.start_time,'employee_schedule_calender');
            const formatedEndDateTime = this.changeDateFormatService.formatDateForFullCalender(slot.service_date, slot.end_time,'employee_schedule_calender');
            if(this.fullCalenderParams.type === 'employee_timing_slots'){
              this.calendarEvents.push({
                id: slot.id,
                rendering: substituteName,
                title: this.fullCalenderParams.type === 'employee_timing_slots' ? slot.slot_name : slot.outlet_service.service.name + '  (' + slot.booked + '/' + slot.total_seats + ')',
                date: formatedDateTime, backgroundColor: slot.subsitute_employee ? '#006600' : '#edc36a', borderColor: slot.subsitute_employee ? '#006600' : '#edc36a'
              });
            } else{
              this.calendarEvents.push({
                id: slot.id,
                rendering: slot.customer,
                title: slot.schedule_name + '  (' + slot.tip + ')',
                start: formatedStartDateTime,
                end: formatedEndDateTime,
                backgroundColor: slot.is_employee_available ? '#006600' : '#edc36a', 
                borderColor: slot.is_employee_available ? '#006600' : '#edc36a'
              });
            }
            
          }
        } else if (response.code === 204) {
          this.calendarEvents = [];
          this.toastrService.error(response.message);
        }
      });
  }

  handleDateClick(arg) { // handler method
    
    console.log(arg.dateStr);
  }

  

  eventClick(info) {
    if (this.fullCalenderParams.type === 'employee_timing_slots') {
      this.startDate = info.event.start;
      this.slotName = info.event.title;
      this.selectedSlotId = info.event.id;
      this.selectedsubstituteName = info.event.rendering;
      this.getSubstituteDetailBySlottId(info.event.id);
      this.currentUserService.OpenCloseModal('substituteListButton');
    } else if (this.fullCalenderParams.type === 'employee_schedule_calender') {
      this.startDate = info.event.start;
      this.slotName = info.event.title;
      this.selectedSlotId = info.event.id;
      this.getEmployeeDetailBySlottId(info.event.id);
      this.currentUserService.OpenCloseModal('customerListButton');
    } else {
      this.getEmployeeDetailBySlottId(info.event.id);
      this.currentUserService.OpenCloseModal('empListButton');
    }


  }

  updateHeader() {
    this.options.header = {
      left: 'prev,next myCustomButton',
      center: 'title',
      right: ''
    };
  }

  updateEvents() {
    this.eventsModel = [{
      title: 'Update Event',
      start: this.yearMonth + '-08',
      end: this.yearMonth + '-10'
    }];
  }


  get yearMonth(): string {
    const dateObj = new Date();
    return dateObj.getUTCFullYear() + '-' + (dateObj.getUTCMonth() + 1);
  }

  onClickBack() {
    if (this.fullCalenderParams.type === 'outlet_slots') {
      this.router.navigate(['/outlet/']);
    } else if (this.fullCalenderParams.type === 'outlet_service_slots') {
      this.router.navigate(['/outlet-service-schedule/' + this.fullCalenderParams.outletId + '/edit/' +
        this.fullCalenderParams.serviceScheduleId]);
    }
  }

  /**
   * Get Outlet service by OutletId
   * @param outletId // Outlet id
   */
  getOutletServiceByOutletId(outletId) {
    if (outletId !== undefined) {
      this.serviceList = [];
      this.userService.getOutletServiceByOutletId
        (UserApi.getOutletServiceListByOutletIdUrl + +outletId + '/').then((response: any) => {
          if (response.code === 200) {
            for (const resultData of response.data) {
              this.serviceList.push({
                id: resultData.id,
                itemName: resultData.service.name,
                serviceDuration: resultData.service.time_duration,
                serviceCategory: resultData.service_category,
              });
            }
          } else if (response.code === 400) {
            if (response.code === null) {
              this.toastrService.error(response.message);
            } else {
              this.currentUserService.errorValidator(response.data);
            }
          } else {
            this.toastrService.error('Oops! Something went wrong. Please try again!');
          }
        });
    }
  }

  /**
  * Patch Multi Select Dropdown values on select
  * @param formControlName  form control name
  * @param event control value
  */
  setSelectedItems(formControlName, selectedItems: any) {
    const selectedIds: any = [];
    const selectedScheduleIds: any = [];
    if (formControlName === 'service') {
      selectedItems.forEach(element => {
        selectedIds.push({
          outlet_service: element.id
        });
      });
    }

  }

  /**
   * Get  outlet List
   */
  getOutletList() {
    
    this.showLoader = true;
    this.userService.getOutletList(UserApi.OutletListUrl + 1 + '/').then(result => {
      this.showLoader = false;
      if (result.code === 200) {
        for (const resultData of result.data) {
          this.OutletList.push({
            id: resultData.id,
            name: resultData.name,
            outletSchedule: resultData.outlet_schedule
          });
        }
        this.selectedOutletId = this.OutletList[0].id;
        this.getEmployeeListByOutletId(this.OutletList[0].id);

      }
    });
  }

  

  /**
   * Get employee list by outletId
   * @param outletId outletId
   */
  getEmployeeListByOutletId(selectedOutletId): void {
    this.employeeList = [];
    this.userService
      .getEmployeeListByOutletId(UserApi.getEmployeeListByOutletIdUrl + selectedOutletId + '/').then(result => {
        if (result.code === 200) {
          for (const resultData of result.data) {
            this.employeeList.push({
              id: resultData.id,
              itemName: resultData.full_name,
            });
          }
          this.selectedEmployeeId = this.employeeList[0].id;
          this.getEmployeeServicesByEmployeeId(this.selectedEmployeeId);
        } else if (result.code === 400) {
          if (result.data === null) {
            this.toastrService.error(result.message);
          } else {
            this.currentUserService.errorValidator(result.data);
          }
        } else {
          this.toastrService.error('Oops! Something went wrong. Please try again!');
        }
      });
  }
  
  /**
   * Get employee services list by employeetId
   * @param employeetId employeetId
   */
  getEmployeeServicesByEmployeeId(selectedEmployeeId): void {
    this.employeeServicesList = [];
    this.userService
      .getServicesListByEmployeeId(UserApi.getEmployeeServicesByEmployeeIdUrl + selectedEmployeeId + '/').then(result => {
        if (result.code === 200) {
          for (const resultData of result.data) {
            this.employeeServicesList.push({
              id: resultData.service.id,
              itemName: resultData.service.name,
            });
          }
          this.selectedServiceId = this.employeeServicesList[0].id;
          this.getServiceSlotsById(this.selectedOutletId, this.selectedServiceId, this.selectedEmployeeId);
          //this.getEmployeeDetails(this.selectedEmployee);
        } else if (result.code === 400) {
          if (result.data === null) {
            // this.toastrService.error(result.message);
          } else {
            this.currentUserService.errorValidator(result.data);
          }
        } else {
          this.toastrService.error('Oops! Something went wrong. Please try again!');
        }
      });
  }



  /**
   * Get employee detail by slotId
   * @param slotId slotId
   */
  getEmployeeDetailBySlottId(slotId): void {
    this.customerBookingList = [];
    this.userService
      .getEmployeeDetailBySlottId(UserApi.bookingCustomerDetail + slotId + '/').then(result => {
        if (result.code === 200) {
          for (const resultData of result.data) {
            this.customerBookingList.push({
              id: resultData.id,
              customer_name: resultData.customer.full_name,
              is_present: resultData.is_present,
            });
          }
          this.selectedEmployee = this.customerBookingList[0].id;
          //this.getEmployeeDetails(this.selectedEmployee);
        } else if (result.code === 400) {
          if (result.data === null) {
            // this.toastrService.error(result.message);
          } else {
            this.currentUserService.errorValidator(result.data);
          }
        } else {
          this.toastrService.error('Oops! Something went wrong. Please try again!');
        }
      });
  }

  /**
   * Get substitute detail by slotId
   * @param slotId slotId
   */
  getSubstituteDetailBySlottId(slotId): void {
    this.substituteList = [];
    this.userService
      .getEmployeeDetailBySlottId(UserApi.getSubstituteDetail + slotId + '/').then(result => {
        if (result.code === 200) {
          for (const resultData of result.data) {
            this.substituteList.push({
              id: resultData.id,
              substitute_name: resultData.full_name
            });
          }
        } else if (result.code === 400) {
          if (result.data === null) {
            // this.toastrService.error(result.message);
          } else {
            this.currentUserService.errorValidator(result.data);
          }
        } else {
          this.toastrService.error('Oops! Something went wrong. Please try again!');
        }
      });
  }


  autocompleListFormatter = (data: any): SafeHtml => {
    const html = `<span>${data.itemName}</span>`;
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  /**
   * Function to patch state
   * @param selectedValue selected value
   */
  // valueChangedService(selectedValue) {
  //   if (selectedValue) {
  //     this.selectedServiceId = selectedValue.id;
  //     this.getServiceSlotsById(this.fullCalenderParams.outletId, this.selectedServiceId, '');
  //     //this.addEditUserForm.patchValue({city: ''});
  //     //this.getCityListByStateId(selectedValue.id);
  //   }
  // }

  // valueChangedEmployee(selectedValue) {
  //   if (selectedValue) {
  //     this.selectedEmployeeId = selectedValue.id;
  //     //this.addEditUserForm.patchValue({city: ''});
  //     //this.getCityListByStateId(selectedValue.id);
  //   }
  // }

  selectAllEmployees(event) {
    const checked = event.target.checked;
    this.customerBookingList.forEach(item => item.is_present = checked);
  }

  changeEmployeeStatus(id, isChecked) {
    this.selectedCustomerArray.push({ id: id });
    let itemiIndex = this.customerBookingList.findIndex(item => item.id === id);
    this.customerBookingList[itemiIndex].is_present = isChecked;
    // if(isChecked) {
    //   this.emailFormArray.push({id:id, is_present: isChecked});
    // } else {
    //   let index = this.emailFormArray.indexOf(id);
    //   this.emailFormArray.splice(index,1);
    // }
  }

  /**
   * Mark As Present/ Absent customer request
   * @param status status
   */
  markAsCustomer(customerStatus) {

    // for (const selectedCustomerId of this.selectedCustomerArray) {
    //   let index1 = this.customerBookingList.findIndex(item => item.id === selectedCustomerId.id);
    //   this.customerBookingList[index1].is_present = customerStatus === 2 ? false : true;
    //   let index2 = this.customerBookingList.findIndex(item => item.id !== selectedCustomerId.id);
    //   this.customerBookingList[index2].is_present = customerStatus === 2 ? true : false;
    // }

    const requestParams = {
      booked_service_attendance: this.customerBookingList,
    };
    this.userService.updateCustomerBookingAttendence(
      UserApi.bookingUpdateCustomerDetail, requestParams).then(result => {
        if (result.code === 200) {
          this.toastrService.success(result.message);
          this.currentUserService.OpenCloseModal('empListButton');
          this.resetCustomerDetail();

        } else if (result.code === 400) {
          if (result.data === null) {
            this.toastrService.error(result.message);
          } else {
            this.currentUserService.errorValidator(result.data);
          }
        } else {
          this.toastrService.error('Oops! Something went wrong. Please try again!');
        }
      });
  }

  cancel () {
    this.currentUserService.OpenCloseModal('confirmBox');
  }

  /**
 *update Substitute
 * @param status status
 */

  updateSubstitute(slotId, status) {
    if (this.addSubstituteForm.valid && status === 2) {
      const requestParams = {
        subsitute_employee: this.addSubstituteForm.value.substitute_name ? this.addSubstituteForm.value.substitute_name : null,
      };
      this.userService.updateSubstitute(
        UserApi.updateSubstituteBySlotId + '/' + +slotId + '/', requestParams).then(result => {
          if (result.code === 200) {
            this.toastrService.success(result.message);
            this.currentUserService.OpenCloseModal('substituteListButton');
            this.getServiceSlotsById(this.fullCalenderParams.outletId, this.fullCalenderParams.serviceId, this.fullCalenderParams.employeeId);
            this.resetSubstituteDetail();
          } else if (result.code === 400) {
            if (result.data === null) {
              this.toastrService.error(result.message);
            } else {
              this.currentUserService.errorValidator(result.data);
            }
          } else {
            this.toastrService.error('Oops! Something went wrong. Please try again!');
          }
        });
    } else if (status === 1) {
      const requestParams = {
        subsitute_employee: this.addSubstituteForm.value.substitute_name ? this.addSubstituteForm.value.substitute_name : null,
      };
      const object = this;
      this.confirmDialogService.confirmThis('Are you sure you want to remove substitute ?', function () {
        object.userService.updateSubstitute(
        UserApi.updateSubstituteBySlotId + '/' + +slotId + '/', requestParams).then(result => {
          if (result.code === 200) {
            object.toastrService.success('Substitute removed successfully');
            //object.toastrService.success(result.message);
            object.currentUserService.OpenCloseModal('substituteListButton');
            object.getServiceSlotsById(object.fullCalenderParams.outletId, object.fullCalenderParams.serviceId, object.fullCalenderParams.employeeId);
            object.resetSubstituteDetail();
          } else if (result.code === 400) {
            if (result.data === null) {
              object.toastrService.error(result.message);
            } else {
              object.currentUserService.errorValidator(result.data);
            }
          } else {
            object.toastrService.error('Oops! Something went wrong. Please try again!');
          }
        });
      }, function () {
      });
    } else {
      this.validateAllFormFields(this.addSubstituteForm);
    }
    
  }

  /**
   * Validate the user role form fields
   * @param formGroup form group name
   */
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
  /**
  * Reset customer form
  */
  resetCustomerDetail() {
    (<HTMLFormElement>document.getElementById("calenderForm")).reset();
  }

  /**
   * Reset customer form
   */
  resetApproveFee() {

  }

  /**
  * Reset substitute form
  */
  resetSubstituteDetail() {
    (<HTMLFormElement>document.getElementById("addSubstituteForm")).reset();
    this.getServiceSlotsById(this.fullCalenderParams.outletId, this.fullCalenderParams.serviceId, this.fullCalenderParams.employeeId);
    this.addSubstituteForm.patchValue({ substitute_name: '' });
  }

  /**
   * Function to filter the employees based on location
   * @param event event
   */
  filterEmployees(event) {
    if (event.target.value) {
      this.selectedOutletId = event.target.value;
      this.getEmployeeListByOutletId(event.target.value);
    }
    
  }
  /**
   * Function to filter the employees services based on employee
   * @param event event
   */
  filterEmployeesServices(event) {
    if (event.target.value) {
      this.selectedEmployeeId = event.target.value;
      this.getEmployeeServicesByEmployeeId(event.target.value);
    }
    
  }
  /**
   * Function to filter the slots
   * @param event event
   */
  filterSlots(event) {
    if (event.target.value) {
      this.selectedServiceId = event.target.value;
      this.getServiceSlotsById(this.selectedOutletId, this.selectedServiceId, this.selectedEmployeeId);
    }
    
  }
}
