import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { CurrentUserService } from '../../../common/services/user/current-user.service';
import { ToastrService } from 'ngx-toastr'; // add toster service
import { Constants } from '../../../common/Constants';
import { ConfirmDialogService } from '../confirm-dialog/confirm-dialog.service';
import { UserService } from '../../../common/services/user/user.service';
import { UserApi } from '../../../modules/user/user-api';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  providers: [ConfirmDialogService, UserService]
  })

export class HeaderComponent implements OnInit {
  currentUserDetails: any  = [];
  url: any;
  showLoader = false;
  menuData: any = [];
  userImageUrl: string;
  currentUser: any = [];
  OutletListV2: any =[];
  selectedOutletId: any;
  selectedOutletShortName: any;
  isUserSet = false;

  constructor(
    private toastrService: ToastrService,
    private router: Router,
    private location: Location,
    public currentUserService: CurrentUserService,
    private userService: UserService,
    private confirmDialogService: ConfirmDialogService,
  ) {
    this.currentUserDetails = this.currentUserService.getVerifiedUser();
    if (this.currentUserDetails.image) {
      this.url = Constants.mediaUrl + this.currentUserDetails.image;
    }
  }

  ngOnInit() {
    // this.getMenuByRole();
  }

  /**
   * Login function
   */
  public isLogin() {

    if (this.currentUserService.isUserLoggedIn()) {
      const currentUser = localStorage.getItem('currentUser');
      this.currentUser = JSON.parse(currentUser);
      
      if (this.currentUser.image) {
        this.url = Constants.mediaUrl + this.currentUser.image;
      } else {
        this.url = null
      }
      if (this.isUserSet == false) {
        this.getOutletList();
      }

      this.isUserSet = true
      return true;
    } else {
      return false;
    }
  }

  /**
  * Get  outlet List
  */
  getOutletList() {
    this.showLoader = true;
    this.userService.getOutletList(UserApi.OutletListV2Url).then(result => {
      this.showLoader = false;
      if (result.code === 200) {
        this.selectedOutletId = localStorage.getItem('userDefaultLocation') ? localStorage.getItem('userDefaultLocation') : 205;
        
        for (const resultData of result.data) {
          this.OutletListV2.push({
            id: resultData.id,
            name: resultData.name,
            short_name: resultData.short_name
          });

          if (resultData.id == this.selectedOutletId) {
            this.selectedOutletShortName = resultData.short_name;
          }

        }
      } 
    });
  }

  /**
  * Set Default Location
  */
  setDefaultLocation(location, short_name) {
    const requestParams = {
      outlet: location
    };

    this.showLoader = true;
    this.userService.updateDetail(
      UserApi.setMemberDefaultLocation, requestParams).then(result => {
        this.showLoader = false;
        if (result.code === 200) {
          // this.toastrService.success(result.message);

          localStorage.setItem('userDefaultLocation', location);
          this.selectedOutletId = location;
          this.selectedOutletShortName = short_name;
          window.location.reload();
        } else if (result.code === 400) {
          if (result.data === null) {
            this.toastrService.error(result.message);
          } else {
            this.currentUserService.errorValidator(result.data);
          }
        } else {
          this.toastrService.error('Oops! Something went wrong. Please try again!');
        }
      });
  }

  /**
   * LogOut User Session
   */
  logOut() {
    const object = this;
    this.confirmDialogService.confirmThis('Are you sure you would like to Sign Out?', function() {
      localStorage.clear();
      object.router.navigate(['/login']);
    }, function () {
    });
  }

  getMenuByRole() {
    this.showLoader = true;
    this.userService.getUserById(UserApi.getMenuByRole + '/').then((response: any) => {
      if (response.code === 200) {
        this.menuData = response.data;
      }
      this.showLoader = false;
    });
  }
}
