import { EventEmitter } from '@angular/core';
import { environment } from '../../environments/environment';

export class Constants {
    static loginbaseUrl = environment.loginBaseUrl;
    static baseUrl = environment.baseUrl;
    static frontEndBaseUrl = environment.frontendBaseUrl;
    static adminBaseUrl = environment.adminBaseUrl;
    static mediaUrl = environment.mediaUrl;
    static scityStripePublishableKey = environment.scityStripePublishableKey;
    static westLAStripePublishableKey = environment.westLAStripePublishableKey;
    static weHoStripePublishableKey = environment.weHoStripePublishableKey;
    static goldGYMStripePublishableKey = environment.goldGYMStripePublishableKey;
    
}
