import { Component, OnInit, Input, QueryList, ViewChildren, ViewChild, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-radio-input',
  templateUrl: './radio-input.component.html',
  styleUrls: ['./radio-input.component.css']
})
export class RadioInputComponent implements OnInit {
  @Input() radioFieldParams: any;
  @Output() getRadioValueOnChange = new EventEmitter<string>();

  constructor() { }

  ngOnInit() {
    console.log(this.radioFieldParams);
  }

  /**
   * Functio to get selected radio button value
   * @param radioFieldValue radio value
   */
  onChangeRadio(radioFieldValue){
    this.getRadioValueOnChange.next(radioFieldValue);
  }

}
