import { Injectable } from '@angular/core';
import { Headers, Http, RequestOptions} from '@angular/http';
import { ApiService } from '../../../common/services/rest-api/api.service';
import 'rxjs/add/operator/toPromise';
import 'rxjs/Rx';

@Injectable()

export class CouponsRestService {

  constructor(private apiService: ApiService) {
  }

    /**
     * @param apiUrl api url
     * @param data  request data
    */
    public createCoupon(apiUrl: string, data: any) {
        return this.apiService.sendFormData(apiUrl, data)
        .toPromise()
        .then((res) => res);
    }

    /**
     * Update service data by id
     * @param apiUrl api url
     * @param data request data
    */
    public updateCoupon(apiUrl: string, data: any) {
        return this.apiService.sendFormDataPutApi(apiUrl, data)
        .toPromise()
        .then((res) => res);
    }


    /**
     * Get service  by id
     * @param apiUrl api url
     * @param data request data
    */
    public getCouponById(apiUrl: string) {
        return this.apiService.getApi(apiUrl)
        .toPromise()
        .then((res) => res);
    }

    /**
     * Function to get all service 
    */

    public getCouponsList(apiUrl: string) {
        return this.apiService.getApi(apiUrl)
        .toPromise()
        .then((res) => res);
    }

    /**
    * Function delete service  by id
    * @param id service Id
    */

    public deleteCouponById(apiUrl: string) {
        return this.apiService.delete(apiUrl)
        .toPromise()
        .then((res) => res);
    }


}
