import { Injectable, EventEmitter } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { CommonApi } from '../../common-api';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Constants } from '../../../common/Constants';
import { ToastrService } from 'ngx-toastr';
import { debug } from 'util';

@Injectable()
export class CurrentUserService {
  token: string = 'Bearer ' + localStorage.getItem('currentUserToken'); // LoggedIn User Token
  constructor(
    private http: HttpClient,
    private router: Router,
    private toastrService: ToastrService
  ) {}

  /**
   * Set verified user
   * @param user user data
   */
  setVerifiedUser(user: any) {
    if (user) {
      localStorage.clear();
      localStorage.setItem('currentUserId', JSON.stringify(user.data.id));
      localStorage.setItem('currentUserCompanyId', JSON.stringify(user.data.company));
      localStorage.setItem('currentUser', JSON.stringify(user.data));
      localStorage.setItem('currentUserToken', user.data.token);
      localStorage.setItem('isUserLoggedIn', 'true');

      for (const user_location of user.data.user_location) {
        if (user_location.primary_location) {
          localStorage.setItem('userDefaultLocation', user_location.outlet);
        }
      }

      this.token = 'Bearer ' + localStorage.getItem('currentUserToken');
    }
  }

  /**
   * Get verified user details
   * @param user user
   */
  public getVerifiedUser() {
    if (localStorage.getItem('isUserLoggedIn')) {
      let currentUser =  localStorage.getItem('currentUser');
      return JSON.parse(currentUser);
    } else {
      return false;
    }
  }

  /**
   * Verify if user is logged in or not
   * @param url
   * @returns {boolean}
   */
  public verifyLogin(url: string): boolean {
    if (localStorage.getItem('isUserLoggedIn')) {
      return true;
    }
    this.router.navigate(['/login']);
    return false;
  }

  /**
   * Check if logged in
   * @returns {boolean}
   */
  public isUserLoggedIn() {
    if (localStorage.getItem('isUserLoggedIn')) {
      return true;
    }
    return false;
  }

  /**
   * Function to toggle all buttons
   * @param btnId butonId
   */
  OpenCloseModal(btnId: string) {
    $('#' + btnId).click();
  }

  /**
   * Funtion to display error mesaages
   */
  errorValidator(errors: any) {
    const errorMessages: any = [];
    for (const key in errors) {
      if (errors.hasOwnProperty(key)) {
        errorMessages.push(errors[key]);
      }
    }
    return this.toastrService.error(errorMessages);
  }

}
