import { Component, OnInit, Input, QueryList, ViewChildren, ViewChild, Output, EventEmitter } from '@angular/core';


@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.css']
})
export class ButtonComponent implements OnInit {
  @Input() buttonParams: any;

  @Output() getInputValueOnChange = new EventEmitter<string>();
  constructor() { }

  ngOnInit() {
  }

}
