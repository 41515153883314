import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.css']
})
export class TimePickerComponent implements OnInit {
  @Input() timePickerParams: any;
  @Output() getTimePickerValueOnChange = new EventEmitter<string>();

  constructor() { }

  ngOnInit() {
  }

  onChangeInput(inputFieldValue) {
    this.getTimePickerValueOnChange.next(inputFieldValue);
  }

}
