import { Injectable } from '@angular/core';
import { Headers, Http, RequestOptions} from '@angular/http';
import { ApiService } from '../../../common/services/rest-api/api.service';
import 'rxjs/add/operator/toPromise';
import 'rxjs/Rx';

@Injectable()
export class OutletRestService {

  constructor(private apiService: ApiService) {
  };

  /**
   * 
   * @param apiUrl api url
   * @param data request data
   */
  public createOutlet(apiUrl: string, data: any) {
    return this.apiService.postApi(apiUrl, data)
      .toPromise()
      .then((res) => res);
  }

  /**
   * Update outlet data by id
   * @param apiUrl api url
   * @param data request data
   */
  public updateOulet(apiUrl: string, data: any) {
    return this.apiService.putApi(apiUrl, data)
      .toPromise()
      .then((res) => res);
  }


  /**
   * Get outlet  by id
   * @param apiUrl api url
   * @param data request data
   */
  public getOutletById(apiUrl: string) {
    return this.apiService.getApi(apiUrl)
      .toPromise()
      .then((res) => res);
  }

  /**
   * Function to get all outlet
   */

  public getOutletList(apiUrl: string) {
    return this.apiService.getApi(apiUrl)
      .toPromise()
      .then((res) => res);
  }


  /**
   * Function delete outlet  by id
   * @param id outlet id
   */

  public delete(apiUrl: string) {
    return this.apiService.delete(apiUrl)
      .toPromise()
      .then((res) => res);
  }

  /**
   * Get Company outlet list
   * @param apiUrl api url
   */
  public getCompanyOutletList(apiUrl: string) {
    return this.apiService.getApi(apiUrl)
      .toPromise()
      .then((res) => res);
  }

  /**
   * Get outlet schedule list by outletID
   * @param apiUrl api url
   */
  public getOutletScheduleListByOutletId(apiUrl: string) {
    return this.apiService.getApi(apiUrl)
      .toPromise()
      .then((res) => res);
  }

  /**
   * Get outlet schedule list by outletID
   * @param apiUrl api url
   */
  public getOutletServiceById(apiUrl: string) {
    return this.apiService.getApi(apiUrl)
      .toPromise()
      .then((res) => res);
  }

  /**
   * Get outlet guest pass list by outletID
   * @param apiUrl api url
   */
  public getOutletSegetGuestPassesListByOutletIdrviceById(apiUrl: string) {
    return this.apiService.getApi(apiUrl)
      .toPromise()
      .then((res) => res);
  }
  /**
   * Get outlet schedule list by outletID
   * @param apiUrl api url
   */
  public getShiftByOutletId(apiUrl: string) {
    return this.apiService.getApi(apiUrl)
      .toPromise()
      .then((res) => res);
  }

  /**
   * Get outlet service booking by outletID
   * @param apiUrl api url
   */
  public getOutletServiceBookingByOutletId(apiUrl: string) {
    return this.apiService.getApi(apiUrl)
      .toPromise()
      .then((res) => res);
  }

  /**
   * Get outlet service cancellation by outletID
   * @param apiUrl api url
   */
  public getOutletServiceCancellationByOutletId(apiUrl: string) {
    return this.apiService.getApi(apiUrl)
      .toPromise()
      .then((res) => res);
  }

  /**
   * 
   * @param apiUrl api url
   * @param data request data
   */
  public createRoom(apiUrl: string, data: any) {
    return this.apiService.postApi(apiUrl, data)
      .toPromise()
      .then((res) => res);
  }

  /**
   * Update outlet data by id
   * @param apiUrl api url
   * @param data request data
   */
  public updateRoom(apiUrl: string, data: any) {
    return this.apiService.putApi(apiUrl, data)
      .toPromise()
      .then((res) => res);
  }

  /**
   * Get outlet room by outletID
   * @param apiUrl api url
   */
  public getOutletRoomByOutletId(apiUrl: string) {
    return this.apiService.getApi(apiUrl)
      .toPromise()
      .then((res) => res);
  }

}
