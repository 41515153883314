import { Injectable } from '@angular/core';
import { Headers, Http, RequestOptions} from '@angular/http';
import { ApiService } from '../../../common/services/rest-api/api.service';
import 'rxjs/add/operator/toPromise';
import 'rxjs/Rx';

@Injectable()
export class OutletServiceScheduleRestService {

  constructor(private apiService: ApiService) {
  };

  public createOutletServiceSchedule(apiUrl: string, data: any) {
    return this.apiService.postApi(apiUrl, data)
      .toPromise()
      .then((res) => res);
  }

  /**
   * Update Outlet Service Schedule data by id
   * @param apiUrl App Url
   * @param data  requested data
   */
  public updateOutletServiceSchedule(apiUrl: string, data: any) {
    return this.apiService.putApi(apiUrl, data)
      .toPromise()
      .then((res) => res);
  }

  /**
   * get shift employee by Schedule id.
   * @param apiUrl App Url
   * @param data  requested data
   */
  public getShiftEmployeeBySchedule(apiUrl: string, data: any) {
    return this.apiService.postApi(apiUrl, data)
      .toPromise()
      .then((res) => res);
  }

  /**
   * Get Service Slots list by serviceId.
   * @param apiUrl App Url
   * @param data  requested data
   */
  public getServiceSlotsByServiceId(apiUrl: string, data: any) {
    return this.apiService.postApi(apiUrl, data)
      .toPromise()
      .then((res) => res);
  }

  /**
   * Get Outlet Service Schedule by id
   * @param apiUrl app url
   * @param data requested data
   */
  public getOutletServiceScheduleById(apiUrl: string) {
    return this.apiService.getApi(apiUrl)
      .toPromise()
      .then((res) => res);
  }

  /**
   * Get Outlet Services by id
   * @param apiUrl app url
   * @param data requested data
   */
  public getOutletServiceById(apiUrl: string) {
    return this.apiService.getApi(apiUrl)
      .toPromise()
      .then((res) => res);
  }

  /**
   * Get Outlet Services by id
   * @param apiUrl app url
   * @param data requested data
   */
     public getOutletServiceInstanceById(apiUrl: string) {
      return this.apiService.getApi(apiUrl)
        .toPromise()
        .then((res) => res);
    }

  /**
   * Get Outlet Services by id
   * @param apiUrl app url
   * @param data requested data
   */
  public getShiftByScheduleId(apiUrl: string) {
    return this.apiService.getApi(apiUrl)
      .toPromise()
      .then((res) => res);
  }

  /**
   * Get Outlet shift by id
   * @param apiUrl app url
   * @param data requested data
   */
  public getOutletShiftByOutletId(apiUrl: string) {
    return this.apiService.getApi(apiUrl)
      .toPromise()
      .then((res) => res);
  }

  /**
   * Get Outlet employee list by shift id
   * @param apiUrl app url
   * @param data requested data
   */
  public getEmployeeListByShiftId(apiUrl: string) {
    return this.apiService.getApi(apiUrl)
      .toPromise()
      .then((res) => res);
  }

  /**
   * Function to get all Outlet Service Schedules
   */

  public getOutletServiceScheduleList(apiUrl: string) {
    return this.apiService.getApi(apiUrl)
      .toPromise()
      .then((res) => res);
  }


  /**
   * Function delete Outlet Service Schedule by id
   * @param id id parm for single data delete
   */

  public delete(apiUrl: string) {
    return this.apiService.delete(apiUrl)
      .toPromise()
      .then((res) => res);
  }

  /**
   * Function delete Outlet Service Schedule repitation by id
   * @param id id parm for single data delete
   */

  public deleteRepitationByRepId(apiUrl: string) {
    return this.apiService.getApi(apiUrl)
      .toPromise()
      .then((res) => res);
  }

  public checkScheduleExist(apiUrl: string, data: any) {
    return this.apiService.postApi(apiUrl, data)
      .toPromise()
      .then((res) => res);
  }


}
