import { Injectable } from '@angular/core';
import { Headers, Http, RequestOptions} from '@angular/http';
import { ApiService } from '../../../common/services/rest-api/api.service';
import 'rxjs/add/operator/toPromise';
import 'rxjs/Rx';

@Injectable()
export class GuestPassesRestService {

  constructor(private http: Http, private apiService: ApiService) {
  }

  /**
   * create new Guest Pass
   * @param apiUrl apiUrl
   * @param data  requested data
   */
  public createGuestPasses(apiUrl: string, data: any) {
    return this.apiService.postApi(apiUrl, data)
      .toPromise()
      .then((res) => res);
  }

  /**
   * Update Guest Pass data by id
   * @param apiUrl App Url
   * @param data  requested data
   */
  public updateGuestPasses(apiUrl: string, data: any) {
    return this.apiService.putApi(apiUrl, data)
      .toPromise()
      .then((res) => res);
  }
  public getUsersListByOutletId(apiUrl: string) {
    return this.apiService.getApi(apiUrl )
      .toPromise()
      .then((res) => res);
  }


  /**
   * Get Guest Pass by id
   * @param apiUrl app url
   * @param data requested data
   */
  public getGuestPassesById(apiUrl: string) {
    return this.apiService.getApi(apiUrl)
      .toPromise()
      .then((res) => res);
  }

  /**
   * Function to get all Guest Passes
   */

  public getGuestPassesList(apiUrl: string) {
    return this.apiService.getApi(apiUrl)
      .toPromise()
      .then((res) => res);
  }
  /**
   * Function to get all Guest Pass Rule List
   */
  public getGuestPassRuleList(apiUrl: string) {
    return this.apiService.getApi(apiUrl)
      .toPromise()
      .then((res) => res);
  }


  /**
   * Function delete Guest Pass by id
   * @param id id parm for single data delete
   */

  public delete(apiUrl: string) {
    return this.apiService.delete(apiUrl)
      .toPromise()
      .then((res) => res);
  }

}
