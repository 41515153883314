import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { ShowErrorsComponent } from '../errors.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute, RouterLinkActive, RouterModule} from '@angular/router';
import { ToastrModule } from 'ngx-toastr';
import { Ng2CompleterModule } from "ng2-completer";
import { MyDatePickerModule } from 'mydatepicker';
import { PredictiveSearchDropdownComponent } from './components/predictive-search-dropdown/predictive-search-dropdown.component';
import { InputFieldComponent } from './components/input-field/input-field.component';
import { ButtonComponent } from './components/button/button.component';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { MultiselectDropdownComponent } from './components/multiselect-dropdown/multiselect-dropdown.component';
import { AutocompleteComponent } from './components/google-places/google-places.component';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { CheckboxInputComponent } from './components/checkbox-input/checkbox-input.component';
import { RadioInputComponent } from './components/radio-input/radio-input.component';
import { TextareaComponent } from './components/textarea/textarea.component';
import { LoaderComponent } from './components/loader/loader.component'; // import the shared modules
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FullCalendarModule } from '@fullcalendar/angular';
import { FullCalenderComponent } from './components/full-calender/full-calender.component';
import { NgxEditorModule } from 'ngx-editor';
import { TimePickerComponent } from './components/time-picker/time-picker.component';
import { ComingSoonComponent } from './components/coming-soon/coming-soon.component';
import { Ng2AutoCompleteModule } from 'ng2-auto-complete';
import { NgxSelectModule, INgxSelectOptions } from 'ngx-select-ex';

const CustomSelectOptions: INgxSelectOptions = { // Check the interface for more options
  optionValueField: 'id',
  optionTextField: 'name',
  keepSelectedItems: false,
  allowClear: true
};

@NgModule({
  declarations: [HeaderComponent, FooterComponent, SidebarComponent,
  ShowErrorsComponent, NotFoundComponent, PredictiveSearchDropdownComponent, InputFieldComponent,
  ButtonComponent, MultiselectDropdownComponent, AutocompleteComponent,
  DatePickerComponent, CheckboxInputComponent, RadioInputComponent,
  TextareaComponent, LoaderComponent, ConfirmDialogComponent, FullCalenderComponent, TimePickerComponent, ComingSoonComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-top-center',
      preventDuplicates: true,
      closeButton: true,
      tapToDismiss: true,
      maxOpened: 1
    }),
    Ng2CompleterModule,
    AngularMultiSelectModule,
    MyDatePickerModule,
    NgbModule,
    FullCalendarModule,
    NgxEditorModule,
    Ng2AutoCompleteModule,
    NgxSelectModule.forRoot(CustomSelectOptions)
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    ShowErrorsComponent,
    FormsModule,
    ReactiveFormsModule,
    PredictiveSearchDropdownComponent,
    InputFieldComponent,
    ButtonComponent,
    AngularMultiSelectModule,
    MultiselectDropdownComponent,
    AutocompleteComponent,
    DatePickerComponent,
    CheckboxInputComponent,
    RadioInputComponent,
    TextareaComponent,
    LoaderComponent,
    ConfirmDialogComponent,
    NgbModule,
    FullCalenderComponent,
    NgxEditorModule,
    NgxSelectModule

  ],
})
export class SharedModule { }
