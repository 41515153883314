import { Component, OnInit, Input, QueryList, ViewChildren, ViewChild, Output, EventEmitter } from '@angular/core';
import { CompleterCmp, CompleterData, CompleterService, CompleterItem, RemoteData } from 'ng2-completer';
import { Router, ActivatedRoute } from '@angular/router'

@Component({
  selector: 'app-checkbox-input',
  templateUrl: './checkbox-input.component.html',
  styleUrls: ['./checkbox-input.component.css']
})
export class CheckboxInputComponent implements OnInit {
  @Input() checkboxFieldParams: any;
  @Input() dataSourceList = [];
  dataSource: any;
  @Output() getSelectedCheckBoxValue = new EventEmitter<string>();

  constructor(
    private completerService: CompleterService
  ) { }

  ngOnInit() {
    this.dataSource = this.completerService.local(
      this.dataSourceList,
      this.checkboxFieldParams.dataSourceListValueParam,
      this.checkboxFieldParams.dataSourceListValueParam
    );
  }

  onSelectCheckbox(isChecked) {
    this.getSelectedCheckBoxValue.next(isChecked);
  }

}
