import { Injectable } from '@angular/core';
import { Headers, Http, RequestOptions} from '@angular/http';
import { ApiService } from '../../../common/services/rest-api/api.service';
import 'rxjs/add/operator/toPromise';
import 'rxjs/Rx';

@Injectable()
export class ServicesRestService {

  constructor(private apiService: ApiService) {
  }

  /**
   * @param apiUrl api url
   * @param data  request data
   */
  public createService(apiUrl: string, data: any) {
    return this.apiService.sendFormData(apiUrl, data)
      .toPromise()
      .then((res) => res);
  }

  /**
   * Update service data by id
   * @param apiUrl api url
   * @param data request data
   */
  public updateService(apiUrl: string, data: any) {
    return this.apiService.sendFormDataPutApi(apiUrl, data)
      .toPromise()
      .then((res) => res);
  }


  /**
   * Get service  by id
   * @param apiUrl api url
   * @param data request data
   */
  public getServicesById(apiUrl: string) {
    return this.apiService.getApi(apiUrl)
      .toPromise()
      .then((res) => res);
  }

  /**
   * Function to get all service 
   */

  public getServicesList (apiUrl: string) {
    return this.apiService.getApi(apiUrl)
      .toPromise()
      .then((res) => res);
  }


  /**
   * get service list by category id
   * @param apiUrl api url
   * @param data request data
   */
  public getServicesListByCatgoryId(apiUrl: string, data: any) {
    return this.apiService.postApi(apiUrl, data)
      .toPromise()
      .then((res) => res);
  }


  /**
   * Function delete service  by id
   * @param id service Id
   */

  public delete(apiUrl: string) {
    return this.apiService.delete(apiUrl)
      .toPromise()
      .then((res) => res);
  }

  /**
   * Disable service data by id
   * @param apiUrl api url
   * @param data request data
   */
  public disableService(apiUrl: string, data: any) {
    return this.apiService.putApi(apiUrl, data)
      .toPromise()
      .then((res) => res);
  }

}
