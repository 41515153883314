import { Component, ViewChild, EventEmitter, Output, OnInit, AfterViewInit, Input } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { } from 'googlemaps';

@Component({
  selector: 'AutocompleteComponent',
  templateUrl: './google-places.component.html',
  styleUrls: ['./google-places.component.css']
})
export class AutocompleteComponent implements OnInit {
  @Input() googlePlaceParams: any;

  @Input() adressType: string;
  @Output() setAddress: EventEmitter<any> = new EventEmitter();
  @ViewChild('addresstext', { static: false }) addresstext: any;

  autocompleteInput: string;
  queryWait: boolean;
  city: string;
  state: string;
  country: string;
  latitude: any;
  longitude;
  addressObj: any = [];
  constructor() {
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.getPlaceAutocomplete();
  }

  /**
   * Function to get the address from google api.
   */
  // private getPlaceAutocomplete() {
  //   const autocomplete = new google.maps.places.Autocomplete(this.addresstext.nativeElement, {
  //     componentRestrictions: { country: ['US' , 'IN'] },
  //     types: [this.adressType]  // 'establishment' / 'address' / 'geocode'
  //   });
  //   google.maps.event.addListener(autocomplete, 'place_changed', () => {
  //     const place = autocomplete.getPlace();
  //     this.addressObj['city'] = place.address_components[1].long_name;
  //     this.addressObj['state'] = place.address_components[2].long_name;
  //     this.addressObj['country'] = place.address_components[3].long_name;
  //     this.addressObj['latitude'] = place.geometry.location.lat();
  //     this.addressObj['longitude'] = place.geometry.location.lng();
  //     this.addressObj['address'] = place.formatted_address;
  //     this.invokeEvent();
  //   });
  // }

  private getPlaceAutocomplete() {
    const autocomplete = new google.maps.places.Autocomplete(this.addresstext.nativeElement, {
      componentRestrictions: { country: ['US' , 'IN'] },
      types: [this.adressType]  // 'establishment' / 'address' / 'geocode'
    });
    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      const place = autocomplete.getPlace();
      for (const placeaddress of place.address_components) {
        const level = placeaddress.types[0];
        if(level === 'administrative_area_level_2') {
         this.addressObj['city'] = placeaddress.long_name;
        }
        if(level === 'administrative_area_level_1') {
          this.addressObj['state'] = placeaddress.long_name;
         }
         if(level === 'country') {
          this.addressObj['country'] = placeaddress.long_name;
         }
      }
      this.addressObj['latitude'] = place.geometry.location.lat();
      this.addressObj['longitude'] = place.geometry.location.lng();
      this.addressObj['address'] = place.formatted_address;
      this.invokeEvent();
    });
  }

  invokeEvent() {
    this.setAddress.emit(this.addressObj);
  }

}
